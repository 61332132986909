import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Authenticator, Heading } from '@aws-amplify/ui-react';
import { UserProvider } from './UserContext';
import { I18n } from 'aws-amplify/utils';
import logoDgo from './assets/DurGobEst.png'
import { TramiteDataGeneralProvider } from './TramiteContext';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('es')
I18n.putVocabulariesForLanguage('es', {
  'Username cannot be empty': 'El nombre de usuario no puede estar vacío',
    'Password cannot be empty': 'La contraseña no puede estar vacía',
    'password is required to signIn': 'La contraseña no puede estar vacía',
    'User does not exist.': 'El usuario no existe',
    'Incorrect username or password.': 'Usuario o Contraseña incorrectos',
    'Attempt limit exceeded, please try after some time.': 'Se superó el límite de intentos. Inténtelo más tarde.',
});
/* const dict = {
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",
  },
  es: {
    'Sign In': 'Iniciar Sesion',
    'Sign Up': 'Regístrate',
    'Create Account': 'Crear Cuenta',
    "Password": "Contraseña",
    'Confirm Password': 'Confirmar Contraseña',
    'Enter your Password': 'Ingrese su Contraseña',
    'Please confirm your Password': 'Confirme su contraseña',
    'Enter your Email': 'Ingrese su correo',
    'Email': 'Correo',
    'Sign in': 'Iniciar Sesion',
    'Forgot your password?': '¿Olvidó su contraseña?',
    'Reset Password': 'Restablecer Contraseña',
    'Enter your phone number': 'Ingrese su número de teléfono',
    'Send code': 'Enviar Código',
    'Back to Sign In': 'Regresar al inicio',
    'Code': 'Código',
    'Code *': 'Código',
    'New Password': 'Nueva Contraseña',
    'Submit': 'Cambiar',
    'Resend Code': 'Reenviar Código',
    'Enter your username': 'Username or Email',
    'Invalid verification code provided, please try again.': 'Código de verificación no válido, por favor intente nuevamente.',
    'Username cannot be empty': 'El nombre de usuario no puede estar vacío',
    'Password cannot be empty': 'La contraseña no puede estar vacía',
    'User already exists': 'El usuario ya existe',
    'Incorrect username or password': 'Nombre de usuario o contraseña incorrectos',
    'Cannot reset password. Please try again later.': 'No se puede restablecer la contraseña. Intente nuevamente más tarde.',
    'The email address or phone number is already associated with another account.': 'La dirección de correo electrónico o número de teléfono ya está asociado con otra cuenta.',
    'We were unable to send the verification code. Please try again later.': 'No pudimos enviar el código de verificación. Por favor, intente nuevamente más tarde.',
    'Invalid email address': 'Dirección de correo electrónico inválida',
    'Password does not meet the minimum strength requirements.': 'La contraseña no cumple con los requisitos mínimos de seguridad.',
    'Password must have at least 8 characters': 'La contraseña debe contener minimo 8 caracteres.',
    'Your passwords must match': 'La contraseña no coincide.',
    'Username/client id combination not found.': 'El campo no puede estar vacio',
    'password is required to signIn': 'La contraseña no puede estar vacía',
    'User does not exist.': 'El usuario no existe',
    'Incorrect username or password.': 'Usuario o Contraseña incorrectos',
    'An account with the given phone_number already exists.': 'Ya existe una cuenta con el número de teléfono proporcionado.',
    'Attempt limit exceeded, please try after some time.': 'Se superó el límite de intentos. Inténtelo más tarde.',
    'Your code is on the way. To log in, enter the code we texted to': 'Tu código está en camino. Para iniciar sesión, ingresa el código que enviamos a',
    'Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.': 'Tu código está en camino. Para iniciar sesión, ingresa el código que te enviamos. Puede tardar un minuto en llegar.',

    
  },
};
 */

const formFields = {
  signIn: {
    username: {
      label: 'Número de Celular',
      dialCode: '+52',
      placeholder: '618*******',
      maxlength: "10",
      type: "tel"
    },
    password: {
      label: "Contraseña",
      placeholder: "Ingrese su contraseña",


    }

  },

  forgotPassword: {
    username: {
      dialCode: '+52',
      placeholder: '618*******',
      maxlength: "10",
      label:"Número de Celular"
    },
  },
  signUp: {
    name: {
      order: 1,
      label: "Nombre",
      placeholder: "Escriba su Nombre"
    },
    phone_number: {
      label: 'Número de Celular',
      dialCode: '+52',
      placeholder: '618*******',
      maxlength: "10",
    },
  },

}

const components = {
  SignIn: {
    Header() {
      /*       const { tokens } = useTheme();
       */
      return (
        <Heading

          level={3}
          style={{ margin: "auto", textAlign: "center", padding: "10px" }}
        >
          <img src={logoDgo} alt='logoDgo' width={350} height={69} />

          <br />Portal de Pagos del Estado de Durango
        </Heading>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      /*       const { tokens } = useTheme();
       */
      return (
        <Heading

          level={3}
          style={{ margin: "auto", textAlign: "center", padding: "10px" }}
        >
          <img src={logoDgo} alt='logoDgo' width={350} height={69} />

          <br />Portal de Pagos del Estado de Durango
        </Heading>
      );
    },
    Footer() {
      return null; // Elimina el botón "Skip"
    },
  },
  ConfirmResetPassword: {
    Header() {
   
      return (
        <Heading
        style={{ margin: "auto", textAlign: "center", padding: "10px" }}
          level={7}
        >
            <img src={logoDgo} alt='logoDgo' width={350} height={69} />
            <br/>
            El código está en camino. Para restablecer tu contraseña, ingresa el código enviado al correo vinculado a esta cuenta. Puede tardar un minuto en llegar; revisa la carpeta de spam o correo no deseado.
        </Heading>
      );
    },
   
  },
  setUpTOTP:{
    Footer() {
      return null; // Elimina el botón "Skip"
    },
  },
  ConfirmSignIn: {
   
    Footer() {
      return null
    },
  },

}
const PrivateRoute = () => {

  return (

    <Authenticator className='AuthForm' formFields={formFields} components={components}
      loginMechanisms={["phone_number"]}
    
    >
      <UserProvider>
        <TramiteDataGeneralProvider>
          <Outlet />

        </TramiteDataGeneralProvider>
      </UserProvider>
    </Authenticator>
  )
    ;
};

export default PrivateRoute;
