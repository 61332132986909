import React from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import logo from "../src/assets/logoicon.png";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router";
import PhoneVerificationGuard from './PhoneVerificationGuard';
const OTP_Active = process.env.REACT_APP_OTP_ACTIVE;
const App = ({ signOut }) => {
  return(
 
      <BrowserRouter>
      <PhoneVerificationGuard isActive={OTP_Active}>

          <AppRouter />
      </PhoneVerificationGuard>
          {/*  <RouterProvider router={router} /> */}
        </BrowserRouter>
    
  )
};

export default App;

export const Head = () => (
  <>
    <link rel="icon" href={logo} />
  </>
);