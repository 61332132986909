import React , {useState, useEffect}from "react";
import {
    View,

} from "@aws-amplify/ui-react";
import * as formik from 'formik';
import * as yup from 'yup';
import { Col, Row, Form, Button,Modal } from "react-bootstrap";
import { getCurrentUser, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
export const ChangeName = () => {
    const { Formik } = formik;
    const navigate = useNavigate();
    const [userInfo, setUserInfo]=useState("");
    const [band, setBand]=useState(false);
    const schema = yup.object().shape({
        Nombre: yup.string().required("Este campo es obligatorio")

    });
    const handleUpdateProfile = async (userAttributes) => {
        try {
            // Obtener el usuario actual
            const user = await getCurrentUser();

            if (!user) {
                throw new Error('No hay usuario autenticado.');
            }
            console.log(userAttributes)
            console.log(user)
            // Actualizar los atributos del usuario
            const updateResults = await updateUserAttributes({ userAttributes });

            console.log('Resultados de la actualización:', updateResults);
            toast.success('Datos Actualizados con éxito', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                
                });
                setBand(!band)
                navigate("/Configuracion")
        } catch (error) {
            console.error('Error al actualizar los datos del usuario:', error);
            toast.error("Error al actualizar los datos del usuario", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    };
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
    
        if (name === "curp") {
            values = values.replace(/[^A-Z0-9]/g, ''); // Solo letras y números sin caracteres especiales
            setFieldValue(name, values);
        } else if (name === "numLicencia") {
            values = values.replace(/[^0-9]/g, ''); // Solo números
            setFieldValue(name, values);
        } else {
            // Permitir letras acentuadas, Ñ, y caracteres especiales
            values = values.replace(/[^A-ZÁÉÍÓÚÑÜ\s'.,-]/g, '');
            setFieldValue(name, values);
        }
    };
    useEffect(() => {
        const obtenerInfo =async()=>{
            const user = await fetchUserAttributes();
            setUserInfo(user.name.toUpperCase())
            console.log(user)
        }
        obtenerInfo()
      }, [band])
      
    return (
        <>
            <div className="FormTramite">
                <View className="FormTitle">Configuración de Cuenta</View>
                <View className='FormDescripcion'>Actualiza tu información</View>
                <Row className='mb-0'>
                    <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                        <button type="button" onClick={() => navigate("/Configuracion")}
                            className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                            <FaArrowLeft /> Volver al Inicio
                        </button>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                    </Form.Group>
                    <Form.Group className='d-grid gap-2' as={Col} md="3">
                    </Form.Group>
                </Row>
                <Formik
                    validationSchema={schema}
                    initialValues={{
                        Nombre: ""
                    }
                    }
                    onSubmit={async (valores, { resetForm }) => {
                        console.log("Valores", valores);
                        const userAu = await fetchUserAttributes();
                        console.log(userAu);

                        handleUpdateProfile({

                            name: valores.Nombre,

                        });

                    }
                    }
                >
                    {({ handleSubmit, handleChange, values, touched, errors , setFieldValue}) => (

                        <Form onSubmit={handleSubmit} noValidate className='ViewActa mt-3'>
                              <Row className="mb-3">
                                <Form.Label>Nombre Actual</Form.Label>
                                <Form.Control
                                
                                defaultValue={userInfo}
                                readOnly
                                disabled

                                />
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} className="position-relative" >
                                  <Form.Label>Ingrese el Nombre Nuevo</Form.Label>
                                  <Form.Control
                                  name="Nombre"
                                  type="text"
                                  value={values.Nombre}
                                  onChange={(e)=>handleInputChange(e, setFieldValue)}
                                  isValid={touched.Nombre && !errors.Nombre}
                                  isInvalid={touched.Nombre && !!errors.Nombre}
                                  />
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">
                                <Button type="submit" style={{ width: "50%", margin: "auto" }} variant="secondary">Guardar cambios</Button>
                            </Row>
                        </Form>

                    )}


                </Formik>
            </div>
          <ToastContainer/>
        </>
    )
}
