import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import {getCurrentUser,fetchUserAttributes} from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import Row from "react-bootstrap/Row";
import { useFormik } from "formik";
import { publishMessage } from "../../graphql/mutations";
import * as yup from "yup";
import { isMobile } from "react-device-detect";
import { Button as ButtonB } from "react-bootstrap";
import ResumenCard from "./ResumenCard";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { toast, ToastContainer } from "react-toastify";
import ModalActions from "./ModalActions";
import { onMessageReceived } from "../../graphql/subscriptions";
import FormPagos from "../FormPagos";
import { useTramiteDataGeneral } from "../../TramiteContext";
import Iframe from "react-iframe";
import { Button, Text, Loader, View } from "@aws-amplify/ui-react";
import { Backdrop, CircularProgress } from "@mui/material";
import imagenTarjeta from "../../assets/mensaje_tarjeta.png";
import PantallaExitoImpuestos from "../PantallaExitoImpuestos";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const steps = ["Información", "Formas de pago"];
const ENDPOINT_SEI = process.env.REACT_APP_SEI_API_ENDPOINT 
const client = generateClient();
export const FormActaMatrimonio = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { data, setCalculo, setData, calculo } = useTramiteDataGeneral();
  const [calculoData, setCalculoData] = useState({});
  const [idForm, setIdForm] = useState("Not Assign");
  const [skipped, setSkipped] = React.useState(new Set());
  const [metodoPago, setMetodoPago] = useState("pago1");
  const [pagoExitoModal, setPagoExitoModal] = useState(false);
  const [pagoFallidoModal, setPagoFallidoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCalculo, setLoadingCalculo] = useState(false);
  const [loadingPago, setLoadingPago] = useState(false);
  const [respuestaPago, setRespuestaPago] = useState({});
  const [pantallaExito, setPantallaExito] = useState(false);
  const [respuestaRecibo, setRespuestaRecibo] = useState({});
  const [decLimiteAlcanzado, setDecLimiteAlcanzado] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ligaPagoMit, setLigaPagoMit] = useState(null);
  const [continuarPago, setContinuarPago] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [expanded, setExpanded] = React.useState(true);
  const [datoVacio, setDatoVacio] = useState(false);

  useEffect(() => {
    generateID();
  }, []);
  const generateID = async () => {
    const {
      username,
    } = await getCurrentUser();
    const id = `ActadeMatrimonio_${username}_${Date.now()}`;
    setIdForm(id);
    return id;
  };

  const validationSchema = yup.object().shape({
    nombre: yup
      .string()
      .transform(function (value) {
        return value ? value.toUpperCase() : value;
      })
      .required("Este Campo es Obligatorio"),
    primerApellido: yup
      .string()
      .transform(function (value) {
        return value ? value.toUpperCase() : value;
      })
      .required("Este Campo es Obligatorio"),
    segundoApellido: yup.string().transform(function (value) {
      return value ? value.toUpperCase() : value;
    }),
    fechaMatrimonio: yup.string().required("Este Campo es Obligatorio").matches(/^(0?[1-9]|[12]\d|3[01])\/(0?[1-9]|1[0-2])\/(19|20)\d{2}$/, 'La fecha debe estar en el formato dd/mm/aaaa'),
    sexo: yup
      .string()
      .transform(function (value) {
        return value ? value.toUpperCase() : value;
      })
      .required("Este Campo es Obligatorio"),
  });

  
  const handleNext = async () => {
    await generateID(); 
    let newSkipped = skipped;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Get the day and pad with leading zero if necessary
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Get the month (0-11) and add 1, then pad with leading zero
    const year = today.getFullYear(); // Get the full year (4 digits)

    return `${day}/${month}/${year}`;
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values) => { },
    initialValues: {
      nombre: "",
      primerApellido: "",
      segundoApellido: "",
      fechaMatrimonio: "",
      sexo: "",
    },
  });

  const handleChange = (event) => {
    formik.handleChange(event);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    formattedValue = formattedValue.replace(/[^\d]/g, "");

    if (formattedValue.length > 2) {
      formattedValue = formattedValue.substring(0, 2) + '/' + formattedValue.substring(2);
    }
    if (formattedValue.length > 5) {
      formattedValue = formattedValue.substring(0, 5) + '/' + formattedValue.substring(5, 9);
    }

    formik.setFieldValue(name, formattedValue);
  };
  const formatearFechaMat = (fechaMatrimonio) => {
    if (fechaMatrimonio) {
      const [day, month, year] = fechaMatrimonio.split('/').map(Number);

      const date = new Date(year, month - 1, day); // cambia el formato ingresado de fecha por el usuario

      const formattedDay = String(date.getDate()).padStart(2, '0');
      const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
      const formattedYear = date.getFullYear();
      return `${formattedYear}/${formattedMonth}/${formattedDay}`;
    }
    return null;
  };
  const formatearFecha = (fecha) => {
    if (fecha) {
      const date = new Date(fecha);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return null;
  };

  const consultaEndpoint = async () => {
    const data = formik.values
    if (data.nombre === "" || data.primerApellido === "" || data.fechaMatrimonio === "" || data.sexo === "") {
      console.log("Falta un elemento");
    } else {
      setLoading(true)
      const authEndpoint = `${ENDPOINT_SEI}/sei/auth`;
      const endpoint = `${ENDPOINT_SEI}/sei/servicios/validate`;

      const requestData = {
        "servicio": {
          "claveServicio": "SERV363",
        },
        "parametroServicio": {
          "nombre": data.nombre,
          "apellidoP": data.primerApellido,
          "apellidoM": data.segundoApellido,
          "fechaMat": formatearFechaMat(data.fechaMatrimonio),
          "tipoActa": "MATRIMONIO",
          "nombreContribuyente": data.nombre + " " + data.primerApellido + " " + data.segundoApellido,
          "sexo": data.sexo,
        },
      };
      console.log("requestData", requestData);

      try {
        // Primera consulta para obtener la autenticación
        const authResponse = await fetch(authEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

          },
          body: JSON.stringify({}),
        });

        if (!authResponse.ok) {
          setLoading(false)
          throw new Error(`Error en la primera consulta: ${authResponse.status}`);
        }
        const authData = await authResponse.json();
        setAuthToken(authData.token);


        // Segunda consulta utilizando el token de autenticación obtenido
        const validateResponse = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData.token}`,
          },
          body: JSON.stringify(requestData),
        }); console.log("validate response", validateResponse)

        if (!validateResponse.ok) {
          const errorData = await validateResponse.json();
          setLoading(false)
          setErrorMessage(errorData.error);
          setPagoFallidoModal(true);

          throw new Error(
            setLoading(false)
            `Error en la segunda consulta: ${validateResponse.status}`
          );
        }

        const validateData = await validateResponse.json();
        setData(validateData);
        if (validateData.length === 0) {
          console.log("no hay datos")
          setCalculo(null);
          setCalculoData(null);
          setDatoVacio(true)
          setLoading(false)
        } else {
          console.log("hay datos")
          calcularServicioSei()
          setLoading(false)

        }
        console.log("validateData", validateData)
        // Aquí puedes manejar la respuesta de la segunda consulta (validateData)
      } catch (error) {
        setLoading(false)
        console.log("error", error.message) }
    };
  };
  const calcularServicioSei = async (generarEstado = false) => {
    const data = formik.values;
    if (data.nombre === "" || data.primerApellido === "" || data.fechaMatrimonio === "" || data.sexo === "") {
      console.log("falta un elemento");
    } else {
      // setLoading(true);
      setLoadingCalculo(true);
      const endpoint = `${ENDPOINT_SEI}/sei/servicios/calculate`;
      const authEndpoint = `${ENDPOINT_SEI}/sei/auth`;


      const requestData = {
        claveServicio: "SERV363",
        nombreContribuyente: data.nombre + " " + data.primerApellido + " " + data.segundoApellido, //Nombre del tramitante (Interno)

        listaParametrosServicios: null,
      }; console.log("requestData de calculate", requestData)

      try {
        // Primera consulta para obtener la autenticación
        const authResponse = await fetch(authEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });

        if (!authResponse.ok) {
          setLoadingCalculo(false)
          throw new Error(
            `Error en la primera consulta: ${authResponse.status}`

          );
        }
        const authData = await authResponse.json();
        setAuthToken(authData.token);

        // Segunda consulta utilizando el token de autenticación obtenido
        const validateResponse = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData.token}`,
          },
          body: JSON.stringify(requestData),
        });
        if (!validateResponse.ok) {
          const errorData = await validateResponse.json();
          setErrorMessage(errorData.error);
          setPagoFallidoModal(true);
          setLoading(false);
          setLoadingCalculo(false)
          throw new Error(
            `Error en la segunda consulta: ${validateResponse.status}`
          );
        }
        const validateData = await validateResponse.json();
        console.log("validate response de calculate", validateData)

        // Aquí puedes manejar la respuesta de la segunda consulta (validateData)
        // setPayloadDeclaraciones(validateData.payload)
        setCalculo({ ...validateData });
        setCalculoData(validateData);
        setLoading(false);
        setLoadingCalculo(false)
        // setPayloadPago(validateData.payload)
      } catch (error) {
        setLoadingCalculo(false)
        console.error("Error en la consulta:", error.message);
      }


    }
  };

  const generarTransaccion = async () => {
    
    console.log(idForm);
    const user = await fetchUserAttributes();
    try {
      const transaccionEndpoint = `${ENDPOINT_SEI}/sei/putEvent`;

      const conceptos = calculoData.concepto?.map((cp) => {
        return {
          clave: cp.concepto,
          concepto: cp.descripcion,
          importe: cp.monto,
        };
      });

      const response = await fetch(transaccionEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          id: idForm,
          payload: {
            "validationRequested": true,

            "data": {
              "razonSocial": formik.values.sexo === "M" ? `${data[0]?.nombre1} ${data[0]?.primer_AP1} ${data[0]?.segundo_AP1 || ""}` : `${data[0]?.nombre2} ${data[0]?.primer_AP2} ${data[0]?.segundo_AP2 || ""}`,
              "user":
                user.name !== undefined
                  ? user.name
                  : "Contribuyente",
              "email": user.email,
              "padron": {
                "nombrePadron": "Acta de Matrimonio",
              },
              "estadoCuenta": {
                "serie": calculo?.serie,
                "identificador": calculo?.identificador,
                "tramiteId": calculo?.tramiteId,
                "loteId": calculo?.loteId,
                "fechaVencimiento": calculo?.fechaVencimiento,
                "total": calculo?.importe, // -- cambio
              },
              "conceptos": conceptos,
              "document": {
                "segundo_AP_PADRE2": data[0]?.segundo_AP_PADRE2 || "",
                "primer_AP_PADRE2": data[0]?.primer_AP_PADRE2 || "",
                "segundo_AP_MADRE2": data[0]?.segundo_AP_MADRE2 || "",
                "nacionalidad_PADRE2": data[0]?.nacionalidad_PADRE2 || "",
                "nacionalidad_MADRE2": data[0]?.nacionalidad_MADRE2 || "",
                "primer_AP_MADRE2": data[0]?.primer_AP_MADRE2 || "",
                "fecha_REG": data[0]?.fecha_REG || "",
                "segundo_AP1": data[0]?.segundo_AP1 || "",
                "nacionalidad1": data[0]?.nacionalidad1 || "",
                "nombre1": data[0]?.nombre1 || "",
                "primer_AP1": data[0]?.primer_AP1 || "",
                "entidad_REG": data[0]?.entidad_REG || "",
                "curp1": data[0]?.curp1 || "",
                "municipio_REG": data[0]?.municipio_REG || "",
                "localidad_REG": data[0]?.localidad_REG || "",
                "primer_AP_PADRE1": data[0]?.primer_AP_PADRE1 || "",
                "nacionalidad_PADRE1": data[0]?.nacionalidad_PADRE1 || "",
                "primer_AP_MADRE1": data[0]?.primer_AP_MADRE1 || "",
                "regimen_MATRIMONIAL": data[0]?.regimen_MATRIMONIAL || "",
                "segundo_AP_MADRE1": data[0]?.segundo_AP_MADRE1 || "",
                "nacionalidad_MADRE1": data[0]?.nacionalidad_MADRE1 || "",
                "segundo_AP_PADRE1": data[0]?.segundo_AP_PADRE1 || "",
                "guid": data[0]?.guid || "",
                "serie": data[0]?.serie || "",
                "acta": data[0]?.acta || "",
                "num_OFI": data[0]?.num_OFI || "",
                "mun_OFI": data[0]?.mun_OFI || "",
                "oficialia": data[0]?.oficialia || "",
                "actas_TEMP_ID": data[0]?.actas_TEMP_ID || "",
                "identificador": data[0]?.identificador || "",
                "folio": data[0]?.folio || "",
                "nombre_MADRE1": data[0]?.nombre_MADRE1 || "",
                "nacionalidad2": data[0]?.nacionalidad2 || "",
                "edad_CTY1": data[0]?.edad_CTY1 || "",
                "municipio2": data[0]?.municipio2 || "",
                "pais2": data[0]?.pais2 || "",
                "edad_CTY2": data[0]?.edad_CTY2 || "",
                "nombre_MADRE2": data[0]?.nombre_MADRE2 || "",
                "segundo_AP2": data[0]?.segundo_AP2 || "",
                "nombre_PADRE1": data[0]?.nombre_PADRE1 || "",
                "nombre2": data[0]?.nombre2 || "",
                "nombre_PADRE2": data[0]?.nombre_PADRE2 || "",
                "localidad2": data[0]?.localidad2 || "",
                "pais1": data[0]?.pais1 || "",
                "curp2": data[0]?.curp2 || "",
                "municipio1": data[0]?.municipio1 || "",
                "primer_AP2": data[0]?.primer_AP2 || "",
                "estado2": data[0]?.estado2 || "",
                "estado1": data[0]?.estado1 || "",
                "localidad1": data[0]?.localidad1 || "",
              },
              "referencia": `${calculoData.referencias[0].referencia}`,
            },
            authToken: authToken,
            userId: user.sub,
            type: "servicios",
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        // Manejar el error según tus necesidades
        console.error("Error en la transacción:", errorData.error);
        setLoadingPago(false);
        return;
      }

      const responseData = await response.json();
      // Manejar la respuesta según tus necesidades
      console.log("Respuesta de la transacción:", responseData);

      return responseData;
    } catch (error) {
      setLoadingPago(false);
      console.error("Error al realizar la transacción:", error.message);
      return error;
    }
  };

  const GenerarLigaPago = async (tipoPago = null) => {
    const user = await fetchUserAttributes();
    // metodoPago : pago1 : card - pago2 : - pago3 :
    // prepara conceptos con lo de calculo
    const opcionPago = tipoPago ? tipoPago : metodoPago;
    //preparar otras referencias
    const conceptos = calculoData.concepto?.map((cp) => {
      return {
        clave: cp.concepto,
        concepto: cp.descripcion,
        importe: cp.monto,
      };
    });
    const otrasRef = calculoData?.referencias?.map((referencia) => {
      const partes = referencia.recaudacion.split("-");
      const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
      const convenio = parteDespuesDelGuion;
      return {
        banco: referencia.recaudacion,
        convenio: convenio ? convenio : "",
        referencia: referencia.referencia,
      };
    });

    if (
      opcionPago === "pago1" ||
      opcionPago === "pago2" ||
      opcionPago === "pago3" ||
      opcionPago === "pago4"
    ) {
      generarTransaccion();
    }
    const variables = opcionPago === "pago1" ?
      {
        "id": idForm,
        "payload": {
          "paymentMethod": "card",
          "email": user.email,
          "user": user.name !== undefined ? user.name : "Contribuyente",
          "tramite": "Acta de Matrimonio",
          "reference": `${calculo?.referencias[0].referencia}`, //"202300011471739766260",
          "monto": `${calculo?.importe}`, //"0.01",
          authToken,
          // taskToken,
          type: "servicios",
        },
      }
      : opcionPago === "pago2" ||
        opcionPago === "pago3" ||
        opcionPago === "pago4"
        ? {
          "id": idForm,
          "payload": {
            "paymentMethod": "spei",
            "data": {
              "user":
                user.name !== undefined
                  ? user.name
                  : "Contribuyente",
              "loteId": `${calculo?.loteId}`,
              "total_pagar": calculo?.importe,
              "tramite": "Acta de Matrimonio",
              "movimiento": "Copia Acta de Matrimonio",
              "fecha_generacion": getCurrentDate(),
              "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`, //"19/10/2022",
              "contribuyente": formik.values.sexo === "M" ? `${data[0]?.nombre1} ${data[0]?.primer_AP1} ${data[0]?.segundo_AP1 || ""}` : `${data[0]?.nombre2} ${data[0]?.primer_AP2} ${data[0]?.segundo_AP2 || ""}`,
              "curp": `${data[0]?.curp || ""}`,
              "referencia": `${calculo?.referencias[0].referencia}`,
              "otras_referencias": otrasRef,
            },
            "email": user.email,
            "procedure": "referencia_pago_servicios",
          },
        }
        : opcionPago === "Recibo"
          ? {
            "id": idForm,
            "payload": {
              "data": {
                user: user.name !== undefined ? user.name : "Contribuyente",
                "num_transaccion": calculoData.loteId,
                "tramite": "Acta de Matrimonio",
                "movimiento": "Acta de Matrimonio",
                "contribuyente": formik.values.sexo === "M" ? `${data[0]?.nombre1} ${data[0]?.primer_AP1} ${data[0]?.segundo_AP1 || ""}` : `${data[0]?.nombre2} ${data[0]?.primer_AP2} ${data[0]?.segundo_AP2 || ""}`,
                "fecha_pago": getCurrentDate(),
                // "curp": `${data[0].curp}`,

                "otros_conceptos": conceptos,
                "total": `${calculoData.importe}`,
                "serie": `${calculoData.serie}`,
                "identificador": `${calculoData.identificador}`,
                "referencia": `${calculoData.referencias[0].referencia}`,
                "loteId": `${calculoData.loteId}`,
                "estatus": "PAGADO",
              },

              "email": user.email,
              "procedure": "recibo_pago_servicios",
              validationRequested: true,
              userId: user.sub,
              authToken,
            },
          }
          : {
            "id": idForm,
            "payload": {
              "paymentMethod": "spei",
              "data": {
                "user": user.name !== undefined ? user.name : "Contribuyente",
                "contribuyente": formik.values.sexo === "M" ? `${data[0]?.nombre1} ${data[0]?.primer_AP1} ${data[0]?.segundo_AP1 || ""}` : `${data[0]?.nombre2} ${data[0]?.primer_AP2} ${data[0]?.segundo_AP2 || ""}`,
                "tramite": "Acta de Matrimonio",
                "movimiento": "Copia Acta de Matrimonio",
                "fecha_generacion": getCurrentDate(),
                "fecha_vigencia": `${formatearFecha(calculo?.fechaVencimiento)}`, //"19/10/2022",
                "otros_conceptos": conceptos,
                "total_pagar": `${calculo?.importe}`,
              },
              "email": user.email,
              "procedure": "presupuesto_servicios",
            },
          };
    // eslint-disable-next-line no-unused-vars
    const Data = await client.graphql({
      query: publishMessage,
      variables: {
        ...variables,
        payload: JSON.stringify(variables.payload),
      },
    });
    console.log("------------", Data);
  };
  useEffect(() => {
    const subscription = client.graphql({
      query: onMessageReceived,
      variables: { id: idForm },
    }).subscribe({
      next: (event) => {
        const receivedMessage = event.data.onMessageReceived;
        const dataRec = JSON.parse(receivedMessage.payload);
        console.log("DATA rec", dataRec);

        if (dataRec?.error) {
          setErrorMessage(dataRec?.error);
          setPagoFallidoModal(true);

          //alert("Hubo un error intentalo de nuevo")
          return;
        }
        if (dataRec.status === "PAGO VALIDADO") {
          //activar loader
          console.log("Validar pago");
          setRespuestaPago(dataRec);
          setLoadingPago(true);
        }
        if (dataRec?.procedure === "paymentUrl") {
          if (dataRec.response.cd_response === "error") {
            //AlertsAction("error")
            setPagoExitoModal(false);
            setPagoFallidoModal(true);
            setLoadingPago(false);
            setLoading(false);
            return;
          }
          if (dataRec.response.cd_response === "success") {
            //AlertsAction("success")
            // setPagoExitoModal(true);
            setPagoFallidoModal(false);
            setLigaPagoMit(dataRec.response.nb_url);
            setLoadingPago(false);
            setLoading(false);
          }
        }
        if (
          dataRec.Procedure === "referencia_pago_servicios" ||
          dataRec.Procedure === "presupuesto_servicios" ||
          dataRec.Procedure === "recibo_pago_servicios"
        ) {
          if (
            dataRec.Procedure === "referencia_pago_servicios" ||
            dataRec.Procedure === "presupuesto_servicios"
          ) {
            window.open(dataRec.TXTBody);
            setLoadingPago(false);
          }
          if (dataRec.Procedure === "recibo_pago_servicios") {
            //activar vista de exito
            setPantallaExito(true);
            setRespuestaRecibo(dataRec);
            setLoadingPago(false);
            setLoading(false);
            return;
          }
          if (dataRec.Procedure === "referencia_pago_servicios") {
            setLoadingPago(false);
            setLoading(false);
            setPagoExitoModal(true);
            setPagoFallidoModal(false);
            return;
          } /* else {
                  setCallFinal(true)
                }*/
          return;
        }
      },
      error: (error) => {
        console.error("Subscription error", error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [idForm]);

  const handleBlur = (field) => {
    formik.setFieldTouched(field, true);
  };


  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">Copia Certificada de Acta de Matrimonio</View>
        <View className="FormDescripcion"></View>
      </div>

      <View className="ViewPrincipal">
        {pantallaExito ? (
          <PantallaExitoImpuestos
            pago={respuestaPago}
            recibo={respuestaRecibo}
            titulo={
              "Acta de Matrimonio"
            }
          />
        ) : ligaPagoMit ? (
          <View>
            <div className="w-full flex justify-end">
              <img
                width={"300px"}
                height={"150px"}
                src={imagenTarjeta}
                alt="Mensaje tarjeta"
              />
            </div>
            <Iframe
              src={ligaPagoMit}
              height="850px"
              width={isMobile ? "99%" : "550px"}
              display="initial"
              styles={{ overflow: "hidden" }}
              position="relative"
            />
          </View>
        ) : (
          <View className="FormContent">
            <Form
              onSubmit={formik.handleSubmit}
              noValidate
              className="ViewActa"
            >
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <></>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 ? (
                      <Box>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="validationFormik102"
                            className="position-relative"
                          >
                            <Form.Label>Primer Apellido*</Form.Label>
                            <Form.Control
                              type="text"
                              name="primerApellido"
                              value={formik.values.primerApellido}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "primerApellido",
                                  e.target.value.toUpperCase()
                                )
                              } // Convertir valor a mayúsculas
                              isValid={
                                formik.touched.primerApellido &&
                                !formik.errors.primerApellido
                              }
                              isInvalid={
                                formik.touched.primerApellido &&
                                !!formik.errors.primerApellido
                              }
                              disabled={loading}
                              onBlur={() => {
                                handleBlur('primerApellido'); consultaEndpoint();
                              }}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.primerApellido}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Segundo Apellido</Form.Label>
                            <Form.Control
                              type="text"
                              name="segundoApellido"
                              value={formik.values.segundoApellido}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "segundoApellido",
                                  e.target.value.toUpperCase()
                                )
                              } // Convertir valor a mayúsculas
                              isValid={
                                formik.touched.segundoApellido &&
                                !formik.errors.segundoApellido
                              }
                              isInvalid={
                                formik.touched.segundoApellido &&
                                !!formik.errors.segundoApellido
                              }
                              disabled={loading}
                              onBlur={() => {
                                handleBlur(); consultaEndpoint();

                              }}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.segundoApellido}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Nombre (s)*</Form.Label>
                            <Form.Control
                              type="text"
                              name="nombre"
                              value={formik.values.nombre}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "nombre",
                                  e.target.value.toUpperCase()
                                )
                              } // Convertir valor a mayúsculas
                              isValid={
                                formik.touched.nombre && !formik.errors.nombre
                              }
                              isInvalid={
                                formik.touched.nombre && !!formik.errors.nombre
                              }
                              disabled={loading}
                              onBlur={() => {
                                handleBlur("nombre"); consultaEndpoint();

                              }}
                            />

                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.nombre}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="validationFormik102"
                            className="position-relative"
                          >
                            <Form.Label>Sexo*</Form.Label>
                            <Form.Select
                              name="sexo"
                              value={formik.values.sexo}
                              disabled={loading}
                              onChange={formik.handleChange}
                              isValid={
                                formik.touched.sexo && !formik.errors.sexo
                              }
                              isInvalid={
                                !!formik.errors.sexo && formik.touched.sexo
                              }
                              onBlur={() => {
                                handleBlur();
                                consultaEndpoint();

                              }}
                            >
                              <option value="">Seleccione...</option>
                              <option value="F">Femenino</option>
                              <option value="M">Masculino</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.sexo}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="position-relative">
                            <Form.Label>Fecha de Matrimonio*</Form.Label>
                            <Form.Control
                              type="text"
                              name="fechaMatrimonio"
                              placeholder="DD/MM/AAAA"
                              maxLength={10}
                              value={formik.values.fechaMatrimonio}
                              onChange={handleDateChange}
                              isValid={
                                formik.touched.fechaMatrimonio &&
                                !formik.errors.fechaMatrimonio
                              }
                              isInvalid={
                                formik.touched.fechaMatrimonio &&
                                !!formik.errors.fechaMatrimonio
                              }
                              disabled={
                                loading
                              }
                              onBlur={() => {
                                handleBlur("fechaMatrimonio");
                                consultaEndpoint();

                              }}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {formik.errors.fechaMatrimonio}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        {loading ? (

                          <div >
                            <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                          </div>

                        ) : null}
                        {data !== null && data.length !== 0 && (
                          <Row
                            className="mb-3"
                            style={{
                              width: "99%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: "auto",
                            }}
                          >
                            <Accordion
                              expanded={expanded}
                              onChange={handleChange("panel1")}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div>
                                  <span className="text-[#a0aec0] text-sm font-semibold">
                                    Resultado del Acta de Matrimonio
                                  </span>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="">
                                  <span className=" text-2xl">
                                    El cónyuge
                                  </span>
                                  <div style={{ display: "flex", }}>
                                    <div>Nombre: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.nombre1 || ""}</div>
                                  </div>
                                  <div style={{ display: "flex", }} >
                                    <div>Apellidos: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0] ? ((data[0].primer_AP1 || "") + " " + (data[0].segundo_AP1 || "")).trim() || "" : ""}</div>
                                  </div>
                                  <div style={{ display: "flex", }}>
                                    <div>Curp: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.curp1 || ""}</div>
                                  </div>
                                  <div style={{ display: "flex", }}>
                                    <div >Lugar de Nacimiento: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0] ? ((data[0].municipio1 || "") + " " + (data[0].estado1 || "")).trim() || "" : ""}</div>
                                  </div>
                                  <div style={{ display: "flex", }}>
                                    <div>Nacionalidad: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.nacionalidad1 || ""}</div>
                                  </div>
                                  <hr className="my-4 border-gray-[#a0aec0]" />
                                  <span className=" text-2xl">
                                    La cónyuge
                                  </span>
                                  <div style={{ display: "flex", }}>
                                    <div>Nombre: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.nombre2 || ""}</div>
                                  </div>
                                  <div style={{ display: "flex", }} >
                                    <div>Apellidos: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0] ? ((data[0].primer_AP2 || "") + " " + (data[0].segundo_AP2 || "")).trim() || "" : ""}</div>
                                  </div>
                                  <div style={{ display: "flex", }}>
                                    <div>Curp: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.curp2 || ""}</div>
                                  </div>
                                  <div style={{ display: "flex", }}>
                                    <div>Lugar de Nacimiento: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0] ? ((data[0].municipio2 || "") + " " + (data[0].estado2 || "")).trim() || "" : ""}</div>
                                  </div>
                                  <div style={{ display: "flex", }}>
                                    <div>Nacionalidad: </div>
                                    <div style={{ textAlign: "left", fontWeight: "bold", paddingLeft: "0.5rem" }}>{data[0]?.nacionalidad2 || ""}</div>
                                  </div>
                                </div>

                              </AccordionDetails>

                            </Accordion>
                          </Row>
                        )}
                      </Box>
                    ) : (
                      activeStep === 1 && (
                        <FormPagos
                          metodoPago={metodoPago}
                          setMetodoPago={setMetodoPago}
                          generarPresupuesto={() => {
                            GenerarLigaPago("presupuesto");
                            /*   setTipoAccion(tipo.PRESUPUESTO) */
                          }}
                          continuarPago={setContinuarPago}
                        />
                      )
                    )}
                  </React.Fragment>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                {activeStep === steps.length - 1 && (
                  <ButtonB
                    variant="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Anterior
                  </ButtonB>
                )}
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === steps.length - 1 && (
                  <ButtonB
                    onClick={() => {
                      /*  setTipoAccion(tipo.PRESUPUESTO) */
                      setLoadingPago(true);
                      GenerarLigaPago("presupuesto");
                    }}
                    variant="secondary"
                    className="mr-3"
                  >
                    Descargar Presupuesto
                  </ButtonB>
                )}
                <ButtonB  onClick={async () => {
                    if (activeStep === steps.length - 1) {
                      if (metodoPago === "pago1") {
                        GenerarLigaPago("pago1");
                        setLoadingPago(true);
                      } else {
                        GenerarLigaPago("pago2");
                        setLoadingPago(true);
                      }
                      return;
                    }
                    if (
                      formik.values.nombre === "" ||
                      formik.values.primerApellido === "" ||
                      formik.values.segundoApellido === "" ||
                      formik.values.sexo === "" ||
                      formik.values.fechaMatrimonio === "" ||
                      (data.length === 0)
                    ) {
                      // Si los campos están vacíos, no continuar
                      return;
                    }
                    await handleNext();
                  }}
                  variant="secondary"
                  disabled={
                    loading ||
                    loadingCalculo ||
                    !formik.isValid ||
                    formik.values.nombre === "" ||
                    formik.values.primerApellido === "" ||
                    formik.values.sexo === "" ||
                    formik.values.fechaMatrimonio === "" ||
                    (activeStep === steps.length - 1 && continuarPago === false)
                  }
                >
                  {activeStep === steps.length - 1
                    ? metodoPago === "pago1"
                      ? "Continuar al pago"
                      : "Descargar referencia de pago"
                    : calculoData?.importe !== 0
                      ? "Siguiente"
                      : "Realizar pago"}
                </ButtonB>
              </Box>
            </Form>
          </View>
        )}
      </View>
      <div>
      {loadingCalculo ? (

        <div >
          <Loader size='x-small' emptyColor="lightgray" filledColor="gray" variation='linear' />
        </div>

      ) : null}
      <ResumenCard tipo="actas" ligaPagoMit={ligaPagoMit} idForm={idForm}/>
      </div>
      <ModalActions
        title={"Los datos no existen"}
        content={"por favor, vuelve a intentarlo"}
        icon={"error"}
        show={datoVacio}
        onHide={() => {
          setDatoVacio(false);
        }}
        buttonAction={() => {
          setDatoVacio(false);
        }}
      />
      <ModalActions
        title={"Se ha generado la referencia de pago con éxito"}
        content={""}
        icon={"check"}
        show={pagoExitoModal}
        onHide={() => {
          setPagoExitoModal(false);
        }}
        buttonAction={() => {
          setPagoExitoModal(false);
        }}
      />
      <ModalActions
        title={"Hubo un problema"}
        content={"por favor, vuelve a intentarlo"}
        icon={"error"}
        desc={errorMessage}
        show={pagoFallidoModal}
        onHide={() => {
          setPagoFallidoModal(false);
          setErrorMessage("");
        }}
        buttonAction={() => {
          setPagoFallidoModal(false);
          setErrorMessage("");
        }}
      />

      <ModalActions
        title={"Advertencia"}
        content={
          "Alcanzaste el limite de declaraciones permitidas en el periodo seleccionado."
        }
        icon={"error"}
        show={decLimiteAlcanzado}
        onHide={() => {
          setDecLimiteAlcanzado(false);
        }}
        buttonAction={() => {
          setDecLimiteAlcanzado(false);
        }}
      />

      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPago}
      >
        <h1>Esperando Respuesta</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
