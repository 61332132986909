import React, { useState, useEffect, useRef } from "react";
import { View, } from "@aws-amplify/ui-react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
/* import { useAuthenticator } from "@aws-amplify/ui-react";
 */
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import numeral from 'numeral';
import Modal from "react-bootstrap/Modal";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { Form, Button, Col, Row } from "react-bootstrap";
const ENDPOINT_DATA = process.env.REACT_APP_DATA_TRAMITES;

export const Tramites = () => {
  /*   const [trans, setTrans] = useState([]);
   */
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
/*   const { user } = useAuthenticator((context) => [context.user]);
 */  const [userData, setUserData] = useState([]);
  const [itemData, setItemData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_TOKEN_VERIFICATION);
  /*  var requestOptions = {
     method: "GET",
     headers: myHeaders,
     redirect: "follow",
   }; */
  /*   useEffect(() => {
      fetch(
        `${process.env.REACT_APP_URL_FRESHSERVICE}/tickets?email=${user.attributes.email}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const DataResult = JSON.parse(result);
  
          setTrans(DataResult);
                         console.log(DataResult);
           
        })
        .catch((error) => console.log("error", error));
    }, []); */

  useEffect(() => {
    const fetchData = async () => {
      const user = await getCurrentUser();

      try {
        const response = await fetch(`${ENDPOINT_DATA}/servicios/data/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.username,
          }),
        });
        const data = await response.json();
        console.log(data);
        setUserData(data);
      } catch (error) {
        console.error("Hubo un error al obtener los datos:", error);
      }
    };

    fetchData();
  }, []);

  const fechaFormat = (fecha) => {
    // Obtener la fecha y hora actual

    // Formatear la fecha y hora actual


    const fechaString = fecha;


    // Formatear la fecha según tus preferencias
    const opcionesDeFormato = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,  // Cambiado a false para usar formato de 24 horas


    };
    const formatoFinal = new Intl.DateTimeFormat('es-MX', opcionesDeFormato).format(new Date(fechaString));
    return formatoFinal;
  };

  const formatoFinalPayment = (fecha) => {
    const fechaStr = fecha; // Fecha de ejemplo en UTC
    const fechaObj = new Date(fechaStr);

    const opciones = {
      timeZone: 'Asia/Magadan',
      year: 'numeric',
      month: 'short', // Mes abreviado
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Formato de 12 horas
    };
    const fechaFormateada = new Intl.DateTimeFormat('es-MX', opciones).format(fechaObj);


    return fechaFormateada;
  }
  function generateOptionsFromArray(dataArray) {
    // Crear un Set para obtener valores únicos de tramite
     /* const uniqueTramites = [...new Set(dataArray.map(item => item.tramite.toUpperCase()).filter(Boolean))];  */
     const uniqueTramites = [
      ...new Set(
        dataArray
          .map(item => item.tramite ? item.tramite.toUpperCase() : null) // Evita llamar toUpperCase() si tramite no existe
          .filter(Boolean) // Filtra valores null, undefined, etc.
      )
    ];
     

    // Ordenar alfabéticamente
    const sortedTramites = uniqueTramites.sort((a, b) => a.localeCompare(b));
    // Generar un array de opciones
    return sortedTramites.map(tramite => (
      <option key={tramite} value={tramite}>
        {tramite}
      </option>
    ));
  }

  const handleModal = (item) => {
    setOpenModal(true);
    setItemData(item);

  };
  const closeModal = () => {
    setOpenModal(false);
    setItemData(null);
  };

  const ListaConceptos = (conceptos) => {
    // Validar si conceptos existe antes de renderizar la lista
    if (!conceptos || conceptos.length === 0) {
      return <p>No hay datos disponibles</p>;
    }


    return (
      <div style={{ display: "grid", gridTemplateColumns: "70px 1fr" }}>
        <div>
          <div className='text-left font-bold text-lg'>Clave</div>
          <ul style={{ padding: "0" }}>
            {conceptos.map((elemento, index) => (
              <li key={index} style={{ display: "flex", justifyContent: "space-between" }}>{elemento.clave}</li>
            ))}
          </ul>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>

            <div className='text-left font-bold text-lg'>Conceptos</div>
            <div className='text-left justify-end font-bold text-lg'>Importe</div>
          </div>
          {conceptos && <div className='text-left'>
            <ul style={{ padding: "0" }}>
              {conceptos.map((elemento, index) => (
                <li key={index} style={{ display: "flex", justifyContent: "space-between" }}><div>{elemento.concepto}</div> <div>{numeral(elemento.importe).format('$0,0.00')}</div></li>
              ))}
            </ul>
          </div>}

        </div>
      </div>
    );
  };

  const ListaPagoReferencia = (tramites) => {
    return (
      <div>
        {tramites.map((tramite, index) => (
          <Accordion key={index} className="font-bold text-lg mb-2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography style={{ fontWeight: "bold" }}>
                {tramite.nombreTramite || "Trámite no disponible"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                {tramite.conceptos.map((concepto, conceptoIndex) => (
                  <React.Fragment key={conceptoIndex}>
                    <div style={{ textAlign: "left" }}>
                      <Typography>{concepto.descripcion || "Descripción no disponible"}</Typography>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <Typography>${concepto.monto.toFixed(2)}</Typography>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  }
  let nombresMeses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  const inputRefs = useRef({});
  const [filters, setFilters] = useState({

    rfc: '',
    status: "",
    name: "",
    createdAt: "",
    tramite: "",
    plate: ''
    // Agrega más campos según tus necesidades
  });
  if (!userData) {
    // Manejo de casos donde info o info.Items es undefined
    return null; // o algún otro manejo de error que prefieras
  }

  // Función para manejar el cambio de valores
  const handleFilterChange = (filterName, value, e) => {
    // Guardamos la posición del cursor
    const cursorPosition = e.target.selectionStart;

    // Actualizamos el estado con el nuevo valor
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));

    // Usamos setTimeout para restaurar la posición del cursor
    setTimeout(() => {
      const inputElement = inputRefs.current[filterName];
      if (inputElement) {
        inputElement.selectionStart = cursorPosition;
        inputElement.selectionEnd = cursorPosition;
      }
    }, 0);

    // Establecemos la página actual a 1
    setCurrentPage(1);
  };
  const filteredData = userData.filter(item => {
    const itemMonth = new Date(item.createdAt).getMonth() + 1;
    return (
      (!filters.status || item?.status?.includes(filters?.status)) &&
      (!filters.name || item?.name?.toUpperCase().includes(filters?.name.toUpperCase())) &&
      (!filters.tramite || item?.tramite?.toUpperCase().includes(filters?.tramite.toUpperCase())) &&
      (!filters.createdAt || itemMonth === Number(filters?.createdAt)) &&
      (!filters.rfc || item?.rfc?.includes(filters?.rfc)) &&
      (!filters.plate || item?.plate?.includes(filters?.plate))
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleChange = (event, value) => {
    setCurrentPage(value);

  };
  return (
    <div className="DivHistorial" >
      <View className="FormTitle mt-3">Historial de pagos y trámites</View>
      <View margin="1rem 0">
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-2">
            <Form.Control
              ref={el => inputRefs.current['rfc'] = el}
              type="text"
              name="RFC"
              value={filters.rfc}
              onChange={e => handleFilterChange('rfc', e.target.value.toUpperCase(),e)}
              placeholder="RFC"
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-2">
            <Form.Control
              ref={el => inputRefs.current['plate'] = el}
              type="text"
              name="plate"
              value={filters.plate}
              onChange={e => handleFilterChange('plate', e.target.value.toUpperCase(),e)}
              placeholder="PLACA"
            />
          </Form.Group>
          {/*     <Form.Group as={Col}  className="mb-2">
              <Form.Control
              type="text"
              value={filters.tramite.toUpperCase()} 
              onChange={e => handleFilterChange('tramite', e.target.value.toUpperCase())}
              name="tramite"
              placeholder="Tramite"
              />
              </Form.Group> */}
          <Form.Group as={Col} md="2" className="mb-2">
            <Form.Select

              type="text"
              onChange={e => handleFilterChange('tramite', e.target.value,e)}
              value={filters.tramite}

            >
              <option value="">TRAMITE</option>
              {generateOptionsFromArray(userData)}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-2">
            <Form.Control
              ref={el => inputRefs.current['name'] = el}
              type="text"
              value={filters.name}
              onChange={e => handleFilterChange('name', e.target.value.toUpperCase(),e)}
              name="name"
              placeholder="NOMBRE"
            />
          </Form.Group>
          {/*      <Form.Group as={Col} md="2" className="mb-2"> 
              <Form.Select
                type="text"
                onChange={e => handleFilterChange('procedure', e.target.value)}
                value={filters.procedure}
                name="procedure"
              >
                <option value="">Seleccionar Tipo</option>
                <option value="1">Automovilista</option>
                <option value="2">Motociclista</option>
              </Form.Select>
            </Form.Group> */}
          <Form.Group as={Col} md="2" className="mb-2">
            <Form.Select
              type="text"
              onChange={e => handleFilterChange('createdAt', e.target.value,e)}
              value={filters.createdAt}
            >
              <option value="">MES</option>
              <option value="1">ENERO</option>
              <option value="2">FEBRERO</option>
              <option value="3">MARZO</option>
              <option value="4">ABRIL</option>
              <option value="5">MAYO</option>
              <option value="6">JUNIO</option>
              <option value="7">JULIO</option>
              <option value="8">AGOSTO</option>
              <option value="9">SEPTIEMBRE</option>
              <option value="10">OCTUBRE</option>
              <option value="11">NOVIEMBRE</option>
              <option value="12">DICIEMBRE</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-2">
            <Form.Select
              type="text"
              onChange={e => handleFilterChange('status', e.target.value,e)}
              value={filters.status}
              name="estatus"
            >
              <option value="">ESTATUS</option>
              <option value="PAGO VALIDADO">PAGO VALIDADO</option>
              <option value="PAGO EN PROCESO">PAGO EN PROCESO</option>
              <option value="PAGO PENDIENTE">PAGO PENDIENTE</option>

            </Form.Select>
          </Form.Group>
          {/* <Form.Group as={Col}   style={{display:"flex", alignItems:"center", justifyContent:"right"}}>
              <Button variant="link" onClick={()=>setReload(!reload)} ><TfiReload /></Button>
            </Form.Group> */}
        </Row>
        {/*  <ul style={{ margin: "2rem", padding: "0" }}>
          {console.log(typeof trans)}
          {trans.length === undefined ? (
            <>
              <h1 style={{ color: "rgba(0,0,0,0.3)", textAlign: "center" }}>
                Sin tramites
              </h1>
            </>
          ) : trans.length === 0 || trans.tickets.length === 0 ? (
            <h1 style={{ color: "rgba(0,0,0,0.3)", textAlign: "center" }}>
              Sin tramites
            </h1>
          ) : (
            trans.tickets.map((tran) => (
              <>
                {console.log(tran)}
                <li style={{ listStyle: "none" }} key={tran.id} id={tran.id}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label style={{ fontWeight: "bold", fontSize: "16px" }}>
                      {tran.subject}
                    </label>
                    <label>${49}</label>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label
                      style={{
                        textTransform: "uppercase",
                        color: "rgba(136,136,136,1)",
                        fontSize: "14px",
                      }}
                    >
                    {fechaFormat(tran.createdAt)}
                    </label>
                    <label
                      style={{
                        textDecoration: "underline",
                        color: "rgba(51,51,51,1)",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      {tran.status === 2
                        ? "Abierto"
                        : tran.status === 3
                          ? "Pediente"
                          : tran.status === 4
                            ? "Resuelto"
                            : tran.status === 5
                              ? "Cerrado"
                              : "Sin Estatus"}
                    </label>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <label style={{ fontSize: "13px" }}>
                      Vigencia: {fechaFormat(tran.updated_at)}
                    </label>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <a
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        color: "black",
                      }}
                      href={`${process.env.REACT_APP_URL_FRESHSERVICE_TICKETS}/support/tickets/${tran.id}`}
                    >
                      Ver mas
                    </a>
                  </div>
                </li>
                <Divider className="mb-3" />
              </>
            ))
          )}
        </ul> */}
      </View>
      <View>
        <>
          <div className="">
            {currentItems.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "stretch",
                  border: "1px solid #ccc",
                  margin: "10px",
                  backgroundColor: "#f0f4f4",
                  borderRadius: "10px",
                  boxShadow: "0,0,0,0.5"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginLeft: "5%",
                    marginTop: "3%",
                    height: "90%",
                  }}
                >
                  {/* Sección izquierda */}
                  <div style={{ flex: "1", textAlign: "left" }}>
                    {item.tramite && (
                      <p className="underline" style={{ fontWeight: "bold" }}>
                        {item.tramite}
                      </p>
                    )}
                    {item.name && (
                      <p className="underline" style={{ fontWeight: "bold" }}>
                        Nombre: {item.name}
                      </p>
                    )}
                    {item.razonSocial && (
                      <p className="underline" style={{ fontWeight: "bold" }}>
                        {item.razonSocial}
                      </p>
                    )}
                    {item.ticketId && (
                      <p className="underline" style={{ fontWeight: "bold" }}>
                        Flotilla: #{item.ticketId}
                      </p>
                    )}
                    {item.plate && (
                      <p className="underline" style={{ fontWeight: "bold" }}>
                        Placa: {item.plate}
                      </p>
                    )}
                    {item.owner && (
                      <p className="underline" style={{ fontWeight: "bold" }}>
                        Propietario: {item.owner}
                      </p>
                    )}
                    {item.catastral && (
                      <p className="underline" style={{ fontWeight: "bold" }}>
                        Clave Catastral: {item.catastral}
                      </p>
                    )}

                    {item.rfc && (
                      <p style={{ fontWeight: "bold" }}>
                        {item.rfc}
                      </p>
                    )}
                    {item.periodType && (
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {item.periodType === "MENSUAL" ? `Periodo: MENSUAL ${nombresMeses[Number(item.startMonth) - 1]} ${item.year}` : item.periodType === "TRIMESTRAL" ? ` Periodo: TRIMESTRAL ${nombresMeses[Number(item.startMonth) - 1]}-${nombresMeses[Number(item.endMonth) - 1]} ${item.year}` : ` Periodo: ANUAL ${item.year}`}

                      </p>
                    )}
                  </div>
                  {/* Sección derecha */}
                  <div style={{ textAlign: "right", flex: "1" }}>
                    {item.createdAt && (
                      <p style={{ fontWeight: "bold" }}>{fechaFormat(item.createdAt)}</p>
                    )}
                    {item.amount !== undefined && (
                      <p style={{ fontWeight: "bold" }}>Total:  {numeral(item.amount).format('$0,0.00')}</p>
                    )}

                    {item.status && (
                      <p
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        {item.status}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    marginLeft: "5%",
                    backgroundColor: "#607472",
                    color: "white",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    cursor: "pointer",
                    width: "10rem"

                  }}
                  onClick={() => handleModal(item)}
                >
                  <button
                    style={{ height: "100%", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}

                  >
                    Ver detalles
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
        <>
          <Modal show={openModal} size="lg">
            <Modal.Header style={{ alignItems: "center" }} >
              <Modal.Title style={{ width: "100%", textAlign: "center" }}>
                Recibo de trámite
              </Modal.Title >
              <button className="close-button" onClick={() => closeModal()} style={{ width: "40px", height: "40px", fontSize: "40px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                &times;
              </button>
            </Modal.Header>
            <Modal.Body>

              <p>
                {itemData && itemData.tramiteId
                  ? "No. Trámite: " + itemData.tramiteId
                  : null}
              </p>
              <p>
                {itemData && itemData.status
                  ? "Estado: " + itemData.status
                  : null}
              </p>
              {/*  <p>
                {itemData && itemData.createdAt
                  ? "Fecha de Trámite: " + fechaFormat(itemData.createdAt)
                  : null}
              </p> */}
              <p>
                {itemData && itemData.paymentDateUTC
                  ? "Fecha de Pago: " + formatoFinalPayment(itemData.paymentDateUTC)
                  : null}
              </p>
              <p>
                {itemData && itemData.periodType
                  ? itemData.periodType === "MENSUAL" ? `Periodo: MENSUAL ${nombresMeses[Number(itemData.startMonth) - 1]} ${itemData.year}` : itemData.periodType === "TRIMESTRAL" ? ` Periodo: TRIMESTRAL ${nombresMeses[Number(itemData.startMonth) - 1]}-${nombresMeses[Number(itemData.endMonth) - 1]} ${itemData.year}` : ` Periodo: ANUAL ${itemData.year}`
                  : null}
              </p>
              <p>
                {itemData && itemData.name
                  ? "Nombre: " + itemData.name
                  : null}
              </p>
              <p>
                {itemData && itemData.catastral
                  ? "Clave Catastral: " + itemData.catastral
                  : null}
              </p>
              <p>
                {itemData && itemData.owner
                  ? "Propietario: " + itemData.owner
                  : null}
              </p>

              {console.log("datos", itemData)}
              {itemData && itemData?.concepts ?
                ListaConceptos(itemData?.concepts) : null}
              {itemData && itemData?.tramites ?
                ListaPagoReferencia(itemData?.tramites) : null}

              {itemData && itemData.amount && (
                <p style={{ fontWeight: "bold", textAlign: "right" }}>Total:  {numeral(itemData.amount).format('$0,0.00')}</p>
              )}
              {itemData && itemData.presupuestoUrl && itemData.status !== "PAGO VALIDADO" ? (
                <button
                  onClick={() => window.open(itemData.presupuestoUrl, "_blank")}
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#607472",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  DESCARGAR PRESUPUESTO
                </button>
              ) : (
                <p></p>
              )}
              <br />
              <br />
              {itemData && itemData.mitUrl && itemData.status !== "PAGO VALIDADO" ? (
                <button
                  onClick={() => window.open(itemData.mitUrl, "_blank")}
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    display: "inline-block",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    backgroundColor: "#607472",
                    color: "white",
                    textAlign: "center",
                    cursor: "pointer",
                  }}

                >
                  Link de pago
                </button>
              ) : (
                <p></p>
              )}
              <br />
              <br />
              {itemData && itemData.reciboPagoUrl ? (
                <button
                  onClick={() => window.open(itemData.reciboPagoUrl, "_blank")}
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#607472",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}

                >
                  DESCARGAR RECIBO DE PAGO
                </button>



              ) : (
                <p></p>
              )}
              <br />
              <br />
              {itemData && itemData.actaUrl ? (
                <button
                  onClick={() => window.open(itemData.actaUrl, "_blank")}
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#607472",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}

                >
                  DESCARGAR COPIA ACTA ONLINE
                </button>



              ) : (
                <p></p>
              )}
              <br />
              <br />
              {itemData && itemData.isrDocumentUrl ? (
                <button
                  onClick={() => window.open(itemData.isrDocumentUrl, "_blank")}
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#607472",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}

                >
                  DESCARGAR Formato Declaración
                </button>



              ) : (
                <p></p>
              )}
              <br />
              <br />
              {itemData && itemData.referenciaPagoUrl && itemData.status !== "PAGO VALIDADO" ? (
                <button
                  onClick={() =>
                    window.open(itemData.referenciaPagoUrl, "_blank")
                  }
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#607472",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  DESCARGAR REFERENCIA DE PAGO
                </button>
              ) : (
                <p></p>
              )}
              {itemData &&
                (
                  <button
                    className="mt-4"
                    onClick={() => closeModal()}
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      display: "inline-block",
                      padding: "10px 20px",
                      backgroundColor: "#666",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    CERRAR
                  </button>
                )
              }
            </Modal.Body>
          </Modal>
        </>
        {filteredData.length > itemsPerPage && (
          <Stack spacing={2} justifyContent="center" alignItems="center" direction="row">
            <Pagination count={Math.ceil(filteredData.length / itemsPerPage)} page={currentPage} onChange={handleChange} />
          </Stack>
        )}
      </View>
    </div>
  );
};
