import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik, validateYupSchema } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
import Select from 'react-select';
import { Backdrop, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR;
export const FormDatosDomicilio = (props) => {
    const [direcciones, setDirecciones] = useState({ items: [] });
    const { datos, setChangeData, changeData } = props;
    const [editar, setEditar] = useState(datos.domicilioResidencia !== null ? true : false);
    const [codigosPostales, setCodigosPostales] = useState({ items: [] });
    const [loadingCp, setLoadingCp] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [localidades, setLocalidades] = useState({ items: [] })
    const [municipios, setMunicipios] = useState({ items: [] })
    const schema = yup.object().shape({
        pais: yup.string().required("Este valor es Obligatorio"),
        estado: yup.string().required("Este valor es Obligatorio"),
        municipio: yup.string().when('origenDomicilio', (origenDomicilio, schema) => {
            return origenDomicilio[0] === '1'
                ? schema.required('Este valor es obligatorio')
                : schema;
        }),
        localidad: yup.string(),
        origenDomicilio: yup.string().required("Este Campo es Obligatorio"),
        colonia: yup.string().required("Este valor es Obligatorio"),
        tipoCalle: yup.string().when('origenDomicilio', (origenDomicilio, schema) => {
            return origenDomicilio[0] === '1'
                ? schema.required('Este valor es obligatorio')
                : schema;
        }),
        calle: yup.string().required("Este valor es Obligatorio"),
        numExterior: yup.string().when('origenDomicilio', (origenDomicilio, schema) => {
            return origenDomicilio[0] === '1'
                ? schema.required('Este valor es obligatorio')
                : schema;
        }),
        numInterior: yup.string(),
        letra: yup.string(),
        codigoPostal: yup.string().when('origenDomicilio', (origenDomicilio, schema) => {
            return origenDomicilio[0] === '1'
                ? schema.required('Este valor es obligatorio')
                : schema;
        }),

    });
    const handleInputChange = (e, setFieldValue, setFieldTouched) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "numExterior" || name === "numInterior") {
            values = values.replace(/[^0-9]/g, '');

            setFieldValue(name, values);
        } else if (name === "codigoPostal") {
            const numericValue = value.replace(/\D/g, "");
            setFieldValue(name, numericValue);
            /*   if (value.length === 5 && datos.domicilioOrigen[0] === "1") {
                  handleObtenrDomicilio(value, setFieldValue, setFieldTouched);
              } */
        }
        else {

            values = values.replace(/[^A-ZÁÉÍÓÚÑÜ0-9\s'.,#-]/g, '');

            setFieldValue(name, values);
        }
    };

    const obtenerCodigosPostales = (estado, setFieldValue, setFieldTouched) => {
        if (estado !== "") {

            setLoadingCp(true)
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "estado": estado
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://chdzv41shb.execute-api.us-east-1.amazonaws.com/master/catalog/codigoPostal", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result)
                    result.items.sort(function (a, b) {
                        return a.codigoPostal - b.codigoPostal
                    });;
                    if (datos.type !== 234) {

                        if (datos.type === 233 && editar === false) {

                            setFieldValue("municipio", "");
                            setFieldValue("localidad", "");
                            setFieldValue("codigoPostal", "");
                            setFieldValue("colonia", "");
                            setFieldTouched("municipio", false, true);
                            setFieldTouched("localidad", false, true);
                            setFieldTouched("codigoPostal", false, true);
                            setFieldTouched("colonia", false, true);
                            setDirecciones({ items: [] });
                        } else if (datos.type === 232) {
                            setFieldValue("municipio", "");
                            setFieldValue("localidad", "");
                            setFieldValue("codigoPostal", "");
                            setFieldValue("colonia", "");
                            setFieldTouched("municipio", false, true);
                            setFieldTouched("localidad", false, true);
                            setFieldTouched("codigoPostal", false, true);
                            setFieldTouched("colonia", false, true);
                            setDirecciones({ items: [] });

                        }


                    }
                    const itemsTransformados = result.items.map(item => ({
                        value: item.codigoPostal,
                        label: `${item.codigoPostal}` // Ajusta el formato de la etiqueta
                    }));
                    console.log(itemsTransformados)
                    setCodigosPostales({ items: itemsTransformados });
                    setLoadingCp(false)
                    if (datos?.domicilioResidencia?.isComplete === true) {
                        setFieldValue("codigoPostal", datos.domicilioResidencia.codigoPostal)
                    }
                })
                .catch((error) => console.error(error));
        } else {
            setFieldValue("municipio", "");
            setFieldValue("localidad", "");
            setFieldValue("codigoPostal", "");
            setFieldValue("colonia", "");
            setFieldTouched("municipio", false, true);
            setFieldTouched("localidad", false, true);
            setFieldTouched("codigoPostal", false, true);
            setFieldTouched("colonia", false, true);
            setDirecciones({ items: [] });

        }

    }
    const handleObtenrDomicilio = async (data, setFieldValue, setFieldTouched) => {
        if (data !== "") {

            setLoadingCp(true)
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "codigoPostal": data
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://chdzv41shb.execute-api.us-east-1.amazonaws.com/master/catalog/codigoPostal", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result)
                    if (result.items.localidad === null) {
                        console.log("1")
                        setFieldValue("localidad", "SIN LOCALIDAD");
                    } else {
                        if (result.items.estado === "DURANGO") {
                            console.log("2")
                            const itemsTransformadosL = result.items.localidad.map(item => ({
                                value: item.toUpperCase(),
                                label: `${item.toUpperCase()}` // Ajusta el formato de la etiqueta
                            }));
                            console.log(itemsTransformadosL)
                            setTimeout(() => {

                                setLocalidades({ items: itemsTransformadosL });
                            }, 1000)
                            const itemsTransformadosM = result.items.municipio.map(item => ({
                                value: item.toUpperCase(),
                                label: `${item.toUpperCase()}` // Ajusta el formato de la etiqueta
                            }));
                            console.log(itemsTransformadosM)
                            setTimeout(() => {

                                setMunicipios({ items: itemsTransformadosM });
                            }, 1000)

                        } else {
                            console.log("3")
                            console.log(result.items.localidad)

                            setFieldValue("localidad", result.items.localidad);

                        }
                    }
                    if (result.items.estado !== "DURANGO") {
                        console.log("4")

                        console.log(result.items.municipio)
                        setFieldValue("municipio", result.items.municipio);
                    }
                    console.log("5")
                    const itemsTransformados = result.items.colonias.map(item => ({
                        value: item.toUpperCase(),
                        label: `${item.toUpperCase()}` // Ajusta el formato de la etiqueta
                    }));
                    console.log(itemsTransformados)
                    setTimeout(() => {

                        setDirecciones({ items: itemsTransformados });
                    }, 1000)

                    setLoadingCp(false);
                    if (datos.domicilioResidencia.isComplete === true) {
                        console.log("6")
                        setTimeout(() => {
                            console.log("Este mensaje se muestra después del retraso");
                            /* setDirecciones({ items: itemsTransformados }); */
                            setFieldValue("localidad", datos.domicilioResidencia.localidad);
                            setFieldValue("municipio", datos.domicilioResidencia.municipio);
                            setFieldValue("colonia", datos.domicilioResidencia.colonia)
                        }, 1100);
                    }

                    setFieldTouched("municipio", false, true);
                    setFieldTouched("localidad", false, true);
                    setFieldTouched("colonia", false, true);
                })
                .catch((error) => console.error(error));
        } else {
            console.log("7")
            setFieldValue("colonia", "");
            setFieldValue("municipio", "");
            setFieldValue("localidad", "");
            setFieldTouched("municipio", false, true);
            setFieldTouched("localidad", false, true);
            setFieldTouched("colonia", false, true);
            setDirecciones({ items: [] });

        }
    };
    const handleObtenerMunicipios = (codigoPostal, tipo, municipio, setFieldValue) => {
        console.log(codigoPostal, tipo, municipio)

        let data;
        if (tipo === "municipio") {
            data = {
                "codigoPostal": codigoPostal
            }
        } else if (tipo === "localidad") {

            data = {
                "codigoPostal": codigoPostal,
                "municipio": municipio
            }
        } else if (tipo === "colonias") {

            data = {
                "codigoPostal": codigoPostal,
                "localidad": municipio
            }
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify(data);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        console.log(requestOptions)

        fetch("https://chdzv41shb.execute-api.us-east-1.amazonaws.com/master/catalog/colonias", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (tipo === "municipio") {
                    if(result.error !== undefined){
                        setMunicipios({ items: [] })
                    }else{

                        const itemsTransformadosM = result.municipios.map(item => ({
                            value: item.toUpperCase(),
                            label: `${item.toUpperCase()}` // Ajusta el formato de la etiqueta
                        }));
                        console.log(itemsTransformadosM)
                        setMunicipios({ items: itemsTransformadosM });
                        setTimeout(() => {
                            
                            if(datos?.domicilioResidencia?.isComplete === true){
                                setFieldValue("municipio", datos.domicilioResidencia.municipio)
                            }
                        }, 1100);
                    }
                } else if (tipo === "localidad") {
                    if(result.error !== undefined){
                        setLocalidades({ items: [] })
                        setDirecciones({ items: [] })
                    }else{

                        const itemsTransformadosL = result.localidades.map(item => ({
                            value: item.toUpperCase(),
                        label: `${item.toUpperCase()}` // Ajusta el formato de la etiqueta
                    }));
                    console.log(itemsTransformadosL)
                    setLocalidades({ items: itemsTransformadosL });
                    setTimeout(() => {
                        
                        if(datos?.domicilioResidencia?.isComplete === true){
                            setFieldValue("localidad", datos.domicilioResidencia.localidad)
                        }
                    }, 1100);
                }
                } else if (tipo === "colonias") {
                    if(result.error !== undefined){
                        setDirecciones({ items: [] })
                    }else{

                        const itemsTransformados = result.colonias.map(item => ({
                            value: item.toUpperCase(),
                            label: `${item.toUpperCase()}` // Ajusta el formato de la etiqueta
                        }));
                    console.log(itemsTransformados)
                    setTimeout(() => {
                        setDirecciones({ items: itemsTransformados });
                        
                        if(datos?.domicilioResidencia?.isComplete === true){
                            console.log("--------------", datos.domicilioResidencia.colonia)
                            setFieldValue("colonia", datos.domicilioResidencia.colonia)
                        }
                    }, 1100);
                }
            }
            })
            .catch((error) => console.error(error));
    }

    return (
        <>
            <View className="">
                <View className="">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            pais: datos.pais !== null ? datos.pais : "",
                            estado: datos.domicilioResidencia !== null ? datos.domicilioResidencia.estado : datos.estado === null ? "" : datos.estado,
                            municipio: datos.domicilioResidencia !== null ? datos.domicilioResidencia.municipio === null ? "" :datos.domicilioResidencia.municipio :"",
                            localidad: datos.domicilioResidencia !== null ? datos.domicilioResidencia.localidad === null ? "" : datos.domicilioResidencia.localidad : "",
                            colonia: datos.domicilioResidencia !== null ? datos.domicilioResidencia.colonia : datos.domicilioResidencia.colonia === null ?  "": datos.domicilioResidencia.colonia,
                            origenDomicilio: datos.domicilioOrigen !== null ? datos.domicilioOrigen[0] : "",
                            tipoCalle: datos.domicilioResidencia !== null ? datos.domicilioResidencia.tipoCalle === undefined ? "" : datos.domicilioResidencia.tipoCalle === null ? "" : datos.domicilioResidencia.tipoCalle : datos.domicilioResidencia.tipoCalle,
                            calle: datos.domicilioResidencia !== null ? datos.domicilioResidencia.calle : datos.domicilioResidencia.calle === null ?  "": datos.domicilioResidencia.calle,
                            numExterior: datos.domicilioResidencia !== null ? datos.domicilioResidencia.numeroExterior === null ? "": datos.domicilioResidencia.numeroExterior :"",
                            numInterior: datos.domicilioResidencia !== null ? datos.domicilioResidencia.numeroInterior === null ? "" : datos.domicilioResidencia.numeroInterior : "",
                            letra: datos.domicilioResidencia !== null ? datos.domicilioResidencia.letra === null ? "" : datos.domicilioResidencia.letra : "",
                            codigoPostal: datos.domicilioResidencia !== null ? datos.domicilioResidencia.codigoPostal === null ? "" : datos.domicilioResidencia.codigoPostal : "",
                        }}
                        onSubmit={async values => {
                            setLoadingSave(true)
                            let data = {};
                            const user = await getCurrentUser();
                                console.log("--------------------------")
                            if (values.origenDomicilio === "1") {
                                data = {
                                    "id": datos.id,
                                    "procedure": datos.procedure,
                                    "type": datos.type,
                                    "usuarioID": user.username,
                                    "domicilioResidencia": {
                                        "pais": parseInt(values.pais),
                                        "estado": values.estado,
                                        "municipio": values.municipio,
                                        "localidad": values.localidad,
                                        "colonia": values.colonia,
                                        "tipoCalle": parseInt(values.tipoCalle),
                                        "calle": values.calle,
                                        "numeroExterior": values.numExterior,
                                        "letra": values.letra,
                                        "numeroInterior": values.numInterior,
                                        "codigoPostal": values.codigoPostal,
                                        "origenDomicilio": parseInt(values.origenDomicilio),
                                        "isComplete": true
                                    },

                                }

                            } else if (values.origenDomicilio === "2") {
                                data = {
                                    "id": datos.id,
                                    "procedure": datos.procedure,
                                    "type": datos.type,
                                    "estado": values.estado,
                                    "usuarioID": user.username,
                                    "domicilioResidencia": {
                                        "pais": values.pais,
                                        "estado": values.estado,
                                        "calle": values.calle,
                                        "colonia": values.colonia,
                                        "municipio": values.municipio,
                                        "origenDomicilio": parseInt(values.origenDomicilio),
                                        "isComplete": true
                                    },

                                }

                            }
                            CrearIteamAPI(data).then(result => {

                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setChangeData(!changeData)
                                    setEditar(true)
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                                setLoadingSave(false)
                            }).catch(error => {
                                console.log(error)
                                setLoadingSave(false)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, handleBlur, values, touched, errors, setFieldValue, setFieldTouched, resetForm }) => {

                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                if (values.origenDomicilio === "1") {
                                    setFieldValue("pais", "159")
                                    obtenerCodigosPostales(datos.estado, setFieldValue, setFieldTouched)
                               
                                        if(values.codigoPostal !== ""){
                                            
                                            handleObtenerMunicipios(values.codigoPostal, "municipio","", setFieldValue)
                                        }
                                        if(values.codigoPostal !== "" && values.municipio !== ""){

                                            handleObtenerMunicipios(values.codigoPostal, "localidad", values.municipio, setFieldValue)
                                        }
                                        if(values.codigoPostal !== "" && values.localidad !== ""){

                                            handleObtenerMunicipios(values.codigoPostal, "colonias", values.localidad, setFieldValue)
                                        }
                                    
                                }
                            }, [values.origenDomicilio])

                            return (
                                <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>
                                    <Row className='mb-3'>
                                        <Form.Label style={{ textDecoration: "underline" }}>Domicilio de Residencia</Form.Label>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>Origen Domicilio*</Form.Label>
                                            <Form.Select
                                                name='origenDomicilio'
                                                type='text'
                                                value={values.origenDomicilio}
                                                disabled
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const selectedResidencia = e.target.value;
                                                    if (selectedResidencia !== values.origenDomicilio) {
                                                        setFieldValue('pais', '');
                                                        setFieldValue('estado', '');
                                                    }
                                                }}
                                                isValid={touched.origenDomicilio && !errors.origenDomicilio}
                                                isInvalid={touched.origenDomicilio && !!errors.origenDomicilio}
                                            >
                                                <option value="">SELECCIONA</option>
                                                <option value="1">NACIONAL</option>
                                                <option value="2">EXTRANJERO</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.origenDomicilio}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} className='position-relative'>
                                            <Form.Label>Pais*</Form.Label>

                                            <OpcionesSelect
                                                id={"PAISES"}
                                                setFieldValue={setFieldValue}
                                                campo={values.pais}
                                                name={"pais"}
                                                isDisabled={true}
                                                valor={"categoria"}
                                                value={values.pais}
                                                onBlur={handleBlur}
                                                touched={touched.pais}
                                                errors={errors.pais}
                                            />
                                            {/*  <Form.Select
                                                name='pais'
                                                type='text'
                                                value={values.pais}
                                                onChange={handleChange}
                                                disabled
                                                isValid={touched.pais && !errors.pais}
                                                isInvalid={touched.pais && !!errors.pais}
                                            >
                                                <option value="">SELECCIONA</option>
                                                <OpcionesSelect id={"PAISES"} valor={"categoria"} setFieldValue={setFieldValue} campo={values.pais} name={"pais"} />


                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.pais}</Form.Control.Feedback> 
                                            */}
                                        </Form.Group>
                                    </Row>

                                    {values.origenDomicilio === "1" ?
                                        <>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Estado*</Form.Label>

                                                    <OpcionesSelect
                                                        id={19}
                                                        setFieldValue={setFieldValue}
                                                        campo={values.estado}
                                                        name={"estado"}
                                                        isDisabled={true}
                                                        value={values.estado}
                                                        onBlur={handleBlur}
                                                        touched={touched.estado}
                                                        errors={errors.estado}
                                                    />
                                                    {/*   <Form.Select
                                                        type="text"
                                                        name="estado"
                                                        disabled
                                                        value={values.estado}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            if (datos.type === 232) {

                                                                obtenerCodigosPostales(e.target.value,)
                                                            }
                                                        }}
                                                        isValid={
                                                            touched.estado && !errors.estado
                                                        }
                                                        isInvalid={
                                                            touched.estado && !!errors.estado
                                                        }
                                                    >
                                                        <option value="">SELECCIONE</option>
                                                        <OpcionesSelect id={19} setFieldValue={setFieldValue} campo={values.estado} name={"estado"} />
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {errors.estado}
                                                    </Form.Control.Feedback> 
                                                    */}
                                                </Form.Group>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Código Postal*</Form.Label>
                                                    <Select
                                                        classNamePrefix="custom-select"
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                        }}
                                                        name="codigoPostal"
                                                        value={codigosPostales?.items.find(option => option.value === values.codigoPostal) || null}
                                                        onChange={option => {
                                                            setFieldValue("codigoPostal", option ? option.value : values.codigoPostal)
/*                                                             handleObtenrDomicilio(option.value, setFieldValue, setFieldTouched)
 */                                                          handleObtenerMunicipios(option.value, "municipio", "", setFieldValue)
                                                        }}
                                                        onBlur={handleBlur}
                                                        isValid={touched.codigoPostal && !errors.codigoPostal}
                                                        isInvalid={touched.codigoPostal && !!errors.codigoPostal}
                                                        options={codigosPostales.items}
                                                        isSearchable={true}
                                                        isDisabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        placeholder="Codigo Postal"
                                                        className={
                                                            touched.codigoPostal && errors.codigoPostal ? 'is-invalid' : touched.codigoPostal ? 'is-valid' : ''
                                                        }
                                                    />
                                                    {touched.codigoPostal && errors.codigoPostal && (
                                                        <Form.Control.Feedback type="invalid" tooltip>
                                                            {errors.codigoPostal}
                                                        </Form.Control.Feedback>
                                                    )}

                                                    {/*   <Form.Select
                                                        name='codigoPostal'
                                                        type='text'
                                                        placeholder='Código Postal'
                                                        value={values.codigoPostal}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                            handleObtenrDomicilio(event.target.value, setFieldValue, setFieldTouched)
                                                        }}
                                                        disabled={editar}
                                                        isValid={touched.codigoPostal && !errors.codigoPostal}
                                                        isInvalid={touched.codigoPostal && !!errors.codigoPostal}
                                                    >
                                                        {codigosPostales !== "" &&
                                                            <>
                                                                <option value="">SELECCIONE</option>
                                                                {codigosPostales.map((item, index) => (
                                                                    <option key={index} value={item.codigoPostal}>{item.codigoPostal}</option>
                                                                ))}
                                                            </>


                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.codigoPostal}</Form.Control.Feedback>
 */}

                                                </Form.Group>
                                            </Row>
                                        </>
                                        : values.origenDomicilio === "2" &&
                                        <>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Estado*</Form.Label>
                                                    <Form.Control
                                                        name='estado'
                                                        type='text'
                                                        placeholder='ESTADO'
                                                        value={values.estado}
                                                        onChange={(event) => handleInputChange(event, setFieldValue)}
                                                        disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        isValid={touched.estado && !errors.estado}
                                                        isInvalid={touched.estado && !!errors.estado}
                                                    />

                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.estado}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                        </>
                                    }
                                    {values.origenDomicilio === "2" ?
                                        <>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Dirección Extranjera 1*</Form.Label>
                                                    <Form.Control
                                                        name='calle'
                                                        type='text'
                                                        value={values.calle}
                                                        onChange={(event) => handleInputChange(event, setFieldValue)}
                                                        disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        isValid={touched.calle && !errors.calle}
                                                        isInvalid={touched.calle && !!errors.calle}
                                                    />

                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.calle}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Dirección Extranjera 2*</Form.Label>
                                                    <Form.Control
                                                        name='colonia'
                                                        type='text'
                                                        value={values.colonia}
                                                        onChange={(event) => handleInputChange(event, setFieldValue)}
                                                        disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        isValid={touched.colonia && !errors.colonia}
                                                        isInvalid={touched.colonia && !!errors.colonia}
                                                    />

                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.colonia}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Dirección Extranjera 3*</Form.Label>
                                                    <Form.Control
                                                        name='municipio'
                                                        type='text'
                                                        value={values.municipio}
                                                        onChange={(event) => handleInputChange(event, setFieldValue)}
                                                        disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        isValid={touched.municipio && !errors.municipio}
                                                        isInvalid={touched.municipio && !!errors.municipio}
                                                    />

                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.municipio}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                        </>

                                        :
                                        <>

                                            {datos?.domicilioResidencia?.isComplete === true && editar === true ?
                                                <Row className='mb-3'>
                                                    <Form.Group as={Col} className='position-relative'>
                                                        <Form.Label>Municipio*</Form.Label>
                                                        <Form.Control
                                                            name='municipio'
                                                            type='text'
                                                            placeholder='Municipio'
                                                            readOnly
                                                            value={datos.domicilioResidencia.municipio}
                                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                                            disabled
                                                            isValid={touched.municipio && !errors.municipio}
                                                            isInvalid={touched.municipio && !!errors.municipio}
                                                        />
                                                        <Form.Control.Feedback type='invalid' tooltip>{errors.municipio}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                :
                                                <>
                                                    {values.origenDomicilio === "1" ?
                                                       

                                                            <Row className='mb-3'>
                                                                <Form.Group as={Col} className='position-relative'>
                                                                    <Form.Label>Municipio*</Form.Label>
                                                                    <Select
                                                                        classNamePrefix="custom-select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        name="municipio"
                                                                        value={municipios.items.find(option => option.value === values.municipio) || null}
                                                                        onChange={option => {
                                                                            setFieldValue("municipio", option ? option.value : values.municipio)
                                                                            handleObtenerMunicipios(values.codigoPostal, "localidad", option.value, setFieldValue)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        isValid={touched.municipio && !errors.municipio}
                                                                        isInvalid={touched.municipio && !!errors.municipio}
                                                                        options={municipios.items}
                                                                        isSearchable={true}
                                                                        isDisabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                                        placeholder="municipio"
                                                                        className={
                                                                            touched.municipio && errors.municipio ? 'is-invalid' : touched.municipio ? 'is-valid' : ''
                                                                        }
                                                                    />
                                                                    {touched.municipio && errors.municipio && (
                                                                        <Form.Control.Feedback type="invalid" tooltip>
                                                                            {errors.municipio}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                </Form.Group>
                                                            </Row>


                                                            

                                                        :

                                                        <Row className='mb-3'>
                                                            <Form.Group as={Col} className='position-relative'>
                                                                <Form.Label>Municipio*</Form.Label>
                                                                <Form.Control
                                                                    name='municipio'
                                                                    type='text'
                                                                    placeholder='Municipio'
                                                                    value={values.municipio}
                                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                                    disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                                    isValid={touched.municipio && !errors.municipio}
                                                                    isInvalid={touched.municipio && !!errors.municipio}
                                                                />
                                                                <Form.Control.Feedback type='invalid' tooltip>{errors.municipio}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Row>
                                                    }
                                                </>

                                            }
                                            <Row className='mb-3'>
                                                {datos?.domicilioResidencia?.isComplete === true && editar === true ?
                                                    <>
                                                        <Form.Group as={Col} className='position-relative'>
                                                            <Form.Label>Localidad*</Form.Label>
                                                            <Form.Control
                                                                name='localidad'
                                                                type='text'
                                                                readOnly
                                                                value={datos.domicilioResidencia.localidad}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} className='position-relative'>
                                                            <Form.Label>Colonia*</Form.Label>
                                                            <Form.Control
                                                                name='colonia'
                                                                type='text'
                                                                readOnly
                                                                value={datos.domicilioResidencia.colonia}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </>
                                                    :

                                                    values.origenDomicilio === "1" ?
                                                        <>
                                                           

                                                                <Form.Group as={Col} className='position-relative'>
                                                                    <Form.Label>Localidad*</Form.Label>
                                                                    <Select
                                                                        classNamePrefix="custom-select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        name="localidad"
                                                                        value={localidades.items.find(option => option.value === values.localidad) || null}
                                                                        onChange={option => {
                                                                            setFieldValue("localidad", option ? option.value : values.localidad)
                                                                            handleObtenerMunicipios(values.codigoPostal, "colonias", option.value, setFieldValue)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        isValid={touched.localidad && !errors.localidad}
                                                                        isInvalid={touched.localidad && !!errors.localidad}
                                                                        options={localidades.items}
                                                                        isSearchable={true}
                                                                        isDisabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                                        placeholder="localidad"
                                                                        className={
                                                                            touched.localidad && errors.localidad ? 'is-invalid' : touched.localidad ? 'is-valid' : ''
                                                                        }
                                                                    />
                                                                    {touched.localidad && errors.localidad && (
                                                                        <Form.Control.Feedback type="invalid" tooltip>
                                                                            {errors.localidad}
                                                                        </Form.Control.Feedback>
                                                                    )}
                                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.localidad}</Form.Control.Feedback>
                                                                </Form.Group>

                                                               
                                                            <Form.Group as={Col} className='position-relative'>
                                                                <Form.Label>Colonia*</Form.Label>
                                                                <Select
                                                                    classNamePrefix="custom-select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    name="colonia"
                                                                    value={direcciones.items.find(option => option.value === values.colonia) || null}
                                                                    onChange={option => {
                                                                        console.log(option)
                                                                        setFieldValue("colonia", option ? option.value : values.colonia)
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    isValid={touched.colonia && !errors.colonia}
                                                                    isInvalid={touched.colonia && !!errors.colonia}
                                                                    options={direcciones.items}
                                                                    isSearchable={true}
                                                                    isDisabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                                    placeholder="Colonia"
                                                                    className={
                                                                        touched.colonia && errors.colonia ? 'is-invalid' : touched.colonia ? 'is-valid' : ''
                                                                    }
                                                                />
                                                                {touched.colonia && errors.colonia && (
                                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                                        {errors.colonia}
                                                                    </Form.Control.Feedback>
                                                                )}
                                                                {/*  <Form.Select
                                                        type="text"
                                                        name="colonia"
                                                        value={values.colonia}
                                                        onChange={handleChange}
                                                        disabled={editar}
                                                        isValid={
                                                            touched.colonia && !errors.colonia
                                                        }
                                                        isInvalid={
                                                            touched.colonia && !!errors.colonia
                                                            }
                                                            >
                                                            <option value="">SELECCIONE</option>
                                                            {direcciones !== null ?
                                                            direcciones.map((data, index) => (
                                                                <option key={index} value={data.toUpperCase()}>
                                                                {data.toUpperCase()}
                                                                </option>
                                                                )) :
                                                                <option>{values.colonia}</option>
                                                                }
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid" tooltip>
                                                                {errors.colonia}
                                                                </Form.Control.Feedback> */}
                                                            </Form.Group>
                                                        </>



                                                        : values.origenDomicilio === "2" &&
                                                        <>
                                                            <Form.Group as={Col} className='position-relative'>
                                                                <Form.Label>Localidad</Form.Label>
                                                                <Form.Control
                                                                    name='localidad'
                                                                    type='text'
                                                                    placeholder='Localidad'
                                                                    value={values.localidad}
                                                                    disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                                    isValid={touched.localidad && !errors.localidad}
                                                                    isInvalid={touched.localidad && !!errors.localidad}
                                                                />
                                                                <Form.Control.Feedback type='invalid' tooltip>{errors.localidad}</Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} className='position-relative'>
                                                                <Form.Label>Colonia*</Form.Label>
                                                                <Form.Control
                                                                    name='colonia'
                                                                    type='text'
                                                                    placeholder='Colonia'
                                                                    value={values.colonia}
                                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                                    disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                                    isValid={touched.colonia && !errors.colonia}
                                                                    isInvalid={touched.colonia && !!errors.colonia}
                                                                />
                                                                <Form.Control.Feedback type='invalid' tooltip>{errors.colonia}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </>
                                                }
                                            </Row>


                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Tipo de Calle*</Form.Label>

                                                    <OpcionesSelect
                                                        id={6}
                                                        setFieldValue={setFieldValue}
                                                        campo={values.tipoCalle}
                                                        name={"tipoCalle"}
                                                        isDisabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        value={values.tipoCalle}
                                                        onBlur={handleBlur}
                                                        touched={touched.tipoCalle}
                                                        errors={errors.tipoCalle}
                                                    />

                                                    {/* <Form.Select
                                                name='tipoCalle'
                                                type='text'
                                                placeholder='Tipo de Calle'
                                                value={values.tipoCalle}
                                                onChange={handleChange}
                                                disabled={editar}
                                                isValid={touched.tipoCalle && !errors.tipoCalle}
                                                isInvalid={touched.tipoCalle && !!errors.tipoCalle}
                                                >


                                                <option value="">SELECCIONA</option>
                                                
                                                <OpcionesSelect id={6} setFieldValue={setFieldValue} campo={values.tipoCalle} name={"tipoCalle"} />
                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid' tooltip>{errors.tipoCalle}</Form.Control.Feedback> */}
                                                </Form.Group>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative'>
                                                    <Form.Label>Calle*</Form.Label>

                                                    <Form.Control
                                                        name='calle'
                                                        type='text'
                                                        placeholder='Calle'
                                                        value={values.calle}
                                                        onChange={(event) => handleInputChange(event, setFieldValue)}
                                                        disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        isValid={touched.calle && !errors.calle}
                                                        isInvalid={touched.calle && !!errors.calle}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.calle}</Form.Control.Feedback>


                                                </Form.Group>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='position-relative' md="4">
                                                    <Form.Label>Número Exterior*</Form.Label>

                                                    <Form.Control
                                                        name='numExterior'
                                                        type='text'
                                                        placeholder='Numero Exterior'
                                                        value={values.numExterior}
                                                        onChange={(event) => handleInputChange(event, setFieldValue)}
                                                        disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        isValid={touched.numExterior && !errors.numExterior}
                                                        isInvalid={touched.numExterior && !!errors.numExterior}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.numExterior}</Form.Control.Feedback>


                                                </Form.Group>
                                                <Form.Group as={Col} className='position-relative' md="4">
                                                    <Form.Label>Número Interior</Form.Label>

                                                    <Form.Control
                                                        name='numInterior'
                                                        type='text'
                                                        placeholder='Numero Interior'
                                                        value={values.numInterior}
                                                        onChange={(event) => handleInputChange(event, setFieldValue)}
                                                        disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        isValid={touched.numInterior && !errors.numInterior}
                                                        isInvalid={touched.numInterior && !!errors.numInterior}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.numInterior}</Form.Control.Feedback>


                                                </Form.Group>
                                                <Form.Group as={Col} className='position-relative' md="4">
                                                    <Form.Label>Letra</Form.Label>

                                                    <Form.Control
                                                        name='letra'
                                                        type='text'
                                                        placeholder='Letra'
                                                        value={values.letra}
                                                        onChange={(event) => handleInputChange(event, setFieldValue)}
                                                        disabled={editar && datos?.domicilioResidencia?.isComplete === true}
                                                        isValid={touched.letra && !errors.letra}
                                                        isInvalid={touched.letra && !!errors.letra}
                                                    />
                                                    <Form.Control.Feedback type='invalid' tooltip>{errors.letra}</Form.Control.Feedback>


                                                </Form.Group>
                                            </Row>
                                        </>
                                    }
                                    {datos.estatus === "Captura" || datos.estatus === "Rechazado" ?
                                        <>
                                            {datos.type === 234 ? null :

                                                <Row className='mb-3'>
                                                    <Form.Group as={Col} className='d-grid'>
                                                        {editar === false && datos.domicilioResidencia !== null ?

                                                            <Button variant='secondary' onClick={() => {setEditar(true)
                                                                resetForm()
                                                            }} >CANCELAR</Button>
                                                            :
                                                            <Button variant='secondary' disabled={datos.domicilioResidencia !== null ? false : true} onClick={() => setEditar(false)} >EDITAR</Button>
                                                        }
                                                    </Form.Group>
                                                    <Form.Group as={Col} className='d-grid'>

                                                        <Button variant='secondary' disabled={editar && datos?.domicilioResidencia?.isComplete === true} type='submit' onClick={()=>console.log(errors)}>GUARDAR</Button>
                                                    </Form.Group>
                                                </Row>
                                            }
                                        </>
                                        : null
                                    }

                                </Form>
                            )
                        }}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingCp}

            >
                <h1>Obteniendo la Información</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingSave}

            >
                <h1>Guardando...</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
