import React, { useState, useEffect } from "react";
import { View} from "@aws-amplify/ui-react";
import * as formik from 'formik';
import * as yup from 'yup';
import { Col, Row, Form, Button } from "react-bootstrap";
import {  fetchUserAttributes, confirmUserAttribute, sendUserAttributeVerificationCode } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
export const VerifyNumberPhone = ({ isActive, children }) => {
    const { Formik } = formik;
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState("");
    const [userInfoValidate, setUserInfoValidate] = useState("");
    const [show, setShow] = useState(false);
    const [band, setBand] = useState(false);
    const handleChangeCode = (event, setFieldValue) => {
        let values;
        values = event.target.value.replace(/[^0-9]/g, '');
        setFieldValue("codeVerify",values);

    };
    const schema = yup.object().shape({
        codeVerify: yup.string().required("Este Campo es Obligatorio"),

    });

    const sendVerificationCodeToNewEmail = async () => {
        try {
            await sendUserAttributeVerificationCode({ userAttributeKey: 'phone_number' });

            setBand(!band)
        } catch (error) {
            console.error('Error al enviar el código de verificación:', error);
            if(error.name === "LimitExceededException"){
                toast.error('Se superó el límite de intentos. Inténtelo más tarde.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    };
    const handleConfirmAttribute = async ({ userAttributeKey, confirmationCode }) => {
        if (!isActive) return;
        try {
            await confirmUserAttribute({ userAttributeKey, confirmationCode })

            console.log("Resultados de la confirmacion")
          
            toast.success("Cambio realizado con éxito", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBand(!band)
            navigate("/Configuracion")
        } catch (error) {
            console.error('Error al actualizar los datos del usuario:', error);
            toast.error('Ocurrió un error al actualizar los datos del perfil.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    useEffect(() => {
        const obtenerInfo = async () => {
            const user = await fetchUserAttributes();
            setUserInfo(user)
            setUserInfoValidate(user)
            console.log(user)
        }
        obtenerInfo()
    }, [band])
     useEffect(() => {
        const obtenerInfo = async () => {
            sendVerificationCodeToNewEmail()
        }
        obtenerInfo()
    }, []) 


    return (
        <>
            <div className="FormTramite">
                <View className="FormTitle">Configuración de Cuenta</View>
                <View className='FormDescripcion'>Actualiza tu información</View>
                <Row className='mb-0'>
                   

                    <Form.Group as={Col} md="6">
                    </Form.Group>
                    <Form.Group className='d-grid gap-2' as={Col} md="3">
                    </Form.Group>
                </Row>
                <Formik
                    validationSchema={schema}
                    initialValues={{

                        codeVerify: "",
                    }
                    }
                    onSubmit={async (valores, { resetForm }) => {
                       
                        handleConfirmAttribute({
                            userAttributeKey: "phone_number",
                            confirmationCode: valores.codeVerify
                        })

                    }
                    }
                >
                    {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (

                        <Form onSubmit={handleSubmit} noValidate className='ViewActa mt-3'>

                            <Row className="mb-3" style={{ display: "flex", margin: "auto", textAlign: "center" }}>
                                <Form.Label>Hemos enviado un código de verificación a tu número de teléfono por favor ingresalo en el siguiente campo.</Form.Label>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group className="position-relative" as={Col}>
                                    <Form.Label>Ingrese el Código de Verificación</Form.Label>

                                    <Form.Control
                                        name="code"
                                        value={values.codeVerify}
                                        type="text"
                                        onChange={(e) => handleChangeCode(e, setFieldValue)}
                                        isValid={touched.codeVerify && !errors.codeVerify}
                                        isInvalid={touched.codeVerify && !!errors.codeVerify}
                                    />

                                      <Form.Control.Feedback type="invalid" tooltip>{errors.codeVerify}</Form.Control.Feedback>
                                </Form.Group>
                                
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col}>

                                      <Button onClick={() => sendVerificationCodeToNewEmail()} variant="link">Reenviar Código</Button> 
                                </Form.Group>
                            </Row>
                            <Row>
                                <Button variant="secondary" type="submit">CONFIRMAR</Button>
                            </Row>
                        </Form>

                    )}


                </Formik>
            </div>
            <ToastContainer />
           
        </>
    )
}
