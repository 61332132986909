import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import Box from "@mui/material/Box";
import { Button as ButtonB } from "react-bootstrap";
import { useFormik } from "formik";
import Iframe from "react-iframe";
import { Button, Loader, View } from "@aws-amplify/ui-react";
import * as yup from "yup";
import FormPagosReferencias from "../FormPagosReferencias";
import { publishMessage } from '../../graphql/mutations';
import { generateClient } from '@aws-amplify/api';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ModalActions from "./ModalActions";
import { Backdrop, CircularProgress } from "@mui/material";
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import PantallaExitoImpuestos from '../PantallaExitoImpuestos';
import imagenTarjeta from '../../assets/mensaje_tarjeta.png'
import { isMobile } from 'react-device-detect';
import Typography from '@mui/material/Typography';
import { onMessageReceived } from '../../graphql/subscriptions';
import { ButtonPago } from "./ButtonPago";


const steps = ["Información", "Pago"];
const tipo = {
  PRESUPUESTO: "presupuesto",
  RECIBO: "Recibo",
  PRE: "precalculo",
  REFERENCIA: "pago2",
  PAGO: "pago1",
};
const SEI_API_ENDPOINT = process.env.REACT_APP_SEI_API_ENDPOINT;
const ENDPOINT_SEI = process.env.REACT_APP_SEI_API_ENDPOINT;
const ENDPOINT_API_DURANGO ="https://bw5ulxlmwh.execute-api.us-east-1.amazonaws.com/master/servicios/sei/referencias/consulta-referencia";

const client = generateClient();

export const FormConsultaReferencia = () => {
  const [authToken, setAuthToken] = useState("");
  const [datos, setDatos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagoRealizado, setPagoRealizado] = useState(null);
  const [estadoReferencia, setEstadoReferencia] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [ligaPagoMit, setLigaPagoMit] = useState(null);
  const [advertenciaPago, setAdvertenciaPago] = useState(null);
  const [continuarPago, setContinuarPago] = useState(false);
  const [pantallaExito, setPantallaExito] = useState(false);
  const [respuestaPago, setRespuestaPago] = useState({});
  const [respuestaRecibo, setRespuestaRecibo] = useState({});
  const [calculoData, setCalculoData] = useState(true);
  const [idForm, setIdForm] = useState("Not Assign");
  const [pagoExitoModal, setPagoExitoModal] = useState(false);
  const [pagoFallidoModal, setPagoFallidoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingPago, setLoadingPago] = useState(false);
  const [metodoPago, setMetodoPago] = useState('pago1');
  const [callPago, setCallPago] = useState(false);
  const [tipoAccion, setTipoAccion] = useState(tipo.PRE)
  const [taskToken, setTaskToken] = useState('')

  const validationSchema = yup.object().shape({
    referencia: yup.number().required("Este Campo es Obligatorio").typeError("El valor debe ser un número")
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      referencia: "",
    },
    onSubmit: async (values) => {
      await requestData(values.referencia);
    },
  });

  const resetFormManual = async () => {
    formik.resetForm();
    // setCalculo(null)
    setCalculoData(null);
    return await generateID();
  };

  const requestData = async (referencia) => {
    setLoadingPago(true)
    const authEndpoint = `${SEI_API_ENDPOINT}/sei/auth`;
    try {
      const authResponse = await fetch(authEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      if (!authResponse.ok) {
        throw new Error(`Error en la primera consulta: ${authResponse.status}`);
      }

      const authData = await authResponse.json();
      setAuthToken(authData.token);

      const response = await fetch(`${SEI_API_ENDPOINT}/sei/referencias/consulta-referencia`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authData.token}`,
        },
        body: JSON.stringify({
          referencia: referencia,
        }),
      });
      console.log(response)
      if (!response.ok) {
        const errorData = await response.json();
        console.log(errorData)
        setDatos(null);
        setCalculoData(true);
        const errorMessage = errorData.error?.toLowerCase().includes("movimiento")
          ? "Referencia consultada no puede ser pagada por el momento."
          : errorData.error;
        setError(errorMessage);
        return;
      }

      const responseData = await response.json();
      setDatos(responseData);
      console.log(responseData);
      setCalculoData(false);

      if (responseData.pagoRealizado) {
        setCalculoData(false);
        setPagoRealizado(responseData);
      } else {
        setPagoRealizado(null);
        setEstadoReferencia(responseData.estado);

      }

      setError(null);
      setLoadingPago(false)
    } catch (error) {
      setDatos(null);

      setLoadingPago(false)
      setError("Error al realizar la consulta: " + error.message);
    } finally {

      setLoadingPago(false)
      setLoading(false);
    }
  };

  const llamarPago = async (tipoP) => {
    if (!datos.datosGenerales.nombre) {
      return
    }
    procesoPago(tipoP)
  }

  const procesoPago = async (tipoP) => {
    setLoadingPago(true)
    if (tipoP === tipo.PAGO) {
      const resp = await generarTransaccion(tipoP);
      return
    }
    GenerarLigaPago(tipoP)
  }


  useEffect(() => {
    if (tipoAccion === tipo.PAGO) {
      setLoadingPago(true)
      setTimeout(async () => {
        await llamarPago(tipoAccion);
      }, 2000);
    }
  }, [callPago])

  const generarTransaccion = async (tipoP) => {
    setLoadingPago(true);
    const user = await fetchUserAttributes();
    //   const conceptos = datos?.tramites?.map(cp => {
    //     return { clave: cp.concepto, concepto: cp.nombreTramite, importe: cp.monto, conceptos: cp.conceptos?.descripcion, monto: cp.tramite.conceptos.monto }
    // })
    // console.log("concep", conceptos)
    try {
      const transaccionEndpoint = `${SEI_API_ENDPOINT}/sei/putEvent`;
      const response = await fetch(transaccionEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          id: idForm,
          payload: {
            validationRequested: true,
            data: {
              razonSocial: datos.datosGenerales.nombre,
              user: user.name !== undefined ? user.name : "Contribuyente",
              referencia: `${datos.estadoCuenta?.referencias[0].referencia}`,
              email: user.email,
              padron: {
                nombrePadron: "Pago de Referencia Pregenerada",
              },
              datosGenerales: datos.datosGenerales,
              estadoCuenta: {
                loteId: `${datos.estadoCuenta.loteId}`,
                fechaVencimiento: `${datos.estadoCuenta.fechaVencimiento}`,
                total: `${datos.datosGenerales.total}`,
              },
              tramites: datos.tramites,
            },
            userId: user.sub,
            type: "referenciasPregeneradas",
          },
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        // Manejar el error según tus necesidades
        console.error("Error en la transacción:", errorData.error);
        setLoadingPago(false);
        setLoading(false);
        return;
      }

      const responseData = await response.json();
      console.log("1-------", responseData);
      const resp = await GenerarLigaPago(tipoP);
      //setLoading(false)
      return responseData;

    } catch (error) {
      console.error("Error al realizar la transacción:", error.message);
      return error;
    }
  };



  const GenerarLigaPago = async (tipoPago = null) => {
    setLoadingPago(true)
    const user = await fetchUserAttributes();
    const opcionPago = tipoPago ? tipoPago : metodoPago



    const variables = {
      id: idForm,
      payload: {
        paymentMethod: "card",
        email: user.email,
        user: user.name !== undefined ? user.name : "Contribuyente",
        tramite: "Pago de Referencia Pregenerada",
        reference: `${datos.estadoCuenta?.referencias[0].referencia}`,
        monto: `${datos.datosGenerales?.total}`,
        type: "referenciasPregeneradas",
      },
    };
    const Data = await client.graphql({
      query: publishMessage,
      variables: {
        ...variables,
        payload: JSON.stringify(variables.payload),
      },
    });
    setLoadingPago(true);
    console.log("-------", Data);
  };

  useEffect(() => {
    generateID();
  }, []);
  const generateID = async () => {
    const user = await getCurrentUser();
    setIdForm(
      `PagoReferenciaPregenerada_${user.username}_${Date.now()}_${uuidv4()}`
    );
  };
  const [message, setMessage] = useState(null);
  useEffect(() => {
    generateID()
  }, [])

  useEffect(() => {
    const subscription = client.graphql({ query: onMessageReceived, variables: { id: idForm } })
      .subscribe({
        next: (event) => {
          const receivedMessage = event.data.onMessageReceived;
          const dataRec = JSON.parse(receivedMessage.payload)
          setMessage(JSON.parse(receivedMessage.payload));
          console.log("--------", dataRec)
          if (dataRec.taskToken) {
            setTaskToken(dataRec.taskToken)
          }
          if (dataRec?.error) {
            setErrorMessage(dataRec?.error)
            setPagoFallidoModal(true)

            //alert("Hubo un error intentalo de nuevo")
            return
          }
          if (dataRec.status === "PAGO VALIDADO") {
            //activar loader
            setRespuestaPago(dataRec)
            setLoadingPago(true)
            //setLoading(false)
          }
          if (dataRec?.procedure === "paymentUrl") {
            if (dataRec.response.cd_response === "error") {
              //AlertsAction("error")
              setPagoExitoModal(false)
              setPagoFallidoModal(true)
              setLoadingPago(false);
              setLoading(false)
              return
            }
            if (dataRec.response.cd_response === "success") {
              //AlertsAction("success")
              setPagoExitoModal(true)
              setPagoFallidoModal(false)
              setLigaPagoMit(dataRec.response.nb_url)
              setLoadingPago(false);
              setLoading(false)
            }
          }
          if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_autos_nuevos" || dataRec.Procedure === "recibo_pago_ref_pregen") {
            if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_autos_nuevos") {
              window.open(dataRec.TXTBody);
              setLoadingPago(false)
              setLoading(false)
            }
            if (dataRec.Procedure === "recibo_pago_ref_pregen") {
              //activar vista de exito
              setPantallaExito(true)
              setRespuestaRecibo(dataRec)
              setLoadingPago(false)
              setLoading(false)
              return
            }
            return
          }
        },
        error: (error) => {
          console.error('Subscription error', error);
        },
      });


    return () => {
      subscription.unsubscribe();
    };
  }, [idForm]);


  const handleNext = async () => {
    // calcularServicioSei(true);
    // setLoadingPago(true);
    await generateID();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">Búsqueda de Referencias</View>
        <View className="FormDescripcion">Consulta y Pago de Referencias.</View>
      </div>

      <View className="ViewPrincipal">
        {pantallaExito ? (
          <PantallaExitoImpuestos
            pago={respuestaPago}
            recibo={respuestaRecibo}
            titulo={"Pago de Referencia Pregenerada"}
          />
        ) : ligaPagoMit ? (
          <View>
              {/* {process.env.REACT_APP_NODE_ENV !== 'production' ?
                <ButtonPago idForm={idForm} calculo={datos} />
                : null
              } */}
            <div className="w-full flex justify-end">
              <img
                width={"300px"}
                height={"150px"}
                src={imagenTarjeta}
                alt="Mensaje tarjeta"
              />
            </div>
            <Iframe
              src={ligaPagoMit}
              height="850px"
              width={isMobile ? "99%" : "550px"}
              display="initial"
              styles={{ overflow: "hidden" }}
              position="relative"
            />
          </View>
        ) : (
          <View className="FormContent">
            <Form
              onSubmit={formik.handleSubmit}
              noValidate
              className="ViewActa"
            >
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      <label>
                        Descripcion: Pulsa el boton para generar tu orden de
                        pago.
                      </label>
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      style={{ justifyContent: "space-between" }}
                    >
                      <Button
                        variant="secondary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        Anterior
                      </Button>

                      <Button type="submit" variant="secondary">
                        Enviar
                      </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 ? (
                      <Box>
                        <Row className="mb-3 mt-3 align-items-end">
                          <Form.Group as={Col} md={10}>
                            <Form.Label>Búsqueda de Referencia*</Form.Label>
                            <Form.Control
                              type="text"
                              name="referencia"
                              placeholder="Referencia"
                              maxLength={21}
                              value={formik.values.referencia}
                              onChange={formik.handleChange}
                              isValid={
                                formik.touched.referencia &&
                                !formik.errors.referencia
                              }
                              isInvalid={
                                formik.touched.referencia &&
                                !!formik.errors.referencia
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.referencia}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Col md={2}>
                            <ButtonB
                              type="submit"
                              variant="secondary"
                              disabled={loading}
                              className="w-100"
                            >
                              {loading ? "Buscando..." : "BUSCAR"}
                            </ButtonB>
                          </Col>
                        </Row>

                        {error && (
                          <p
                            style={{
                              color: "black",
                              textDecoration: "underline",
                            }}
                          >
                            {error}
                          </p>
                        )}

                        {datos &&
                          (estadoReferencia === "vencida" ||
                            estadoReferencia === "cancelada") && (
                            <div className="estado-referencia">
                              <Row className="mb-3">
                                <Form.Group as={Col}>
                                  <Form.Label>Total a Pagar</Form.Label>
                                  <Form.Control
                                    value={`$${datos.totalPagar}`}
                                    readOnly
                                  />
                                </Form.Group>

                                <Form.Group as={Col}>
                                  <Form.Label>
                                    Vigencia de la Referencia
                                  </Form.Label>
                                  <Form.Control
                                    value={datos.vigencia}
                                    readOnly
                                  />
                                </Form.Group>
                              </Row>

                              <Row className="mb-3">
                                <Form.Group as={Col}>
                                  <Form.Label>Padrón</Form.Label>
                                  <Form.Control value={datos.padron} readOnly />
                                </Form.Group>
                              </Row>
                            </div>
                          )}

                        {pagoRealizado && (
                          <div className="pago-realizado">
                            <Row className="mb-3">
                              <Col>
                                <Form.Group>
                                  <Form.Label>Número de Transacción</Form.Label>
                                  <Form.Control
                                    value={pagoRealizado.numeroTransaccion}
                                    readOnly
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Padrón</Form.Label>
                                  <Form.Control
                                    value={pagoRealizado.padron}
                                    readOnly
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Detalles</Form.Label>
                                  <Table striped bordered hover>
                                    <thead>
                                      <tr>
                                        <th>Clave</th>
                                        <th>Concepto</th>
                                        <th>Importe</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pagoRealizado.detalles.map(
                                        (detalle, index) => (
                                          <tr key={index}>
                                            <td>{detalle.clave}</td>
                                            <td>{detalle.concepto}</td>
                                            <td>
                                              ${detalle.importe.toFixed(2)}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        )}

                        {datos && !pagoRealizado && (
                          <div className="resultado">
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                className="position-relative"
                              >


                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Trámite</th>
                                      <th>Descripción</th>
                                      <th>Monto</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {datos?.tramites.map((tramite, index) => (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td
                                            rowSpan={tramite.conceptos.length}
                                          >
                                            {tramite.nombreTramite ||
                                              "No disponible"}
                                          </td>
                                          <td>
                                            {tramite.conceptos[0]
                                              ?.descripcion || "No disponible"}
                                          </td>
                                          <td className="text-right">
                                            ${tramite.conceptos[0].monto.toFixed(
                                              2
                                            )}
                                          </td>
                                        </tr>

                                        {tramite.conceptos
                                          .slice(1)
                                          .map((concepto, conceptoIndex) => (
                                            <tr key={conceptoIndex}>
                                              <td>
                                                {concepto.descripcion ||
                                                  "No disponible"}
                                              </td>
                                              <td className="text-right">
                                                ${concepto.monto.toFixed(2)}
                                              </td>
                                            </tr>
                                          ))}
                                      </React.Fragment>
                                    ))}
                                  </tbody>
                                </Table>
                                <Form.Group>
                                  <Form.Label>Importe a Pagar</Form.Label>
                                  <Form.Control
                                    value={`$${datos.datosGenerales.total.toFixed(
                                      2
                                    )}`}
                                    readOnly
                                  />
                                </Form.Group>

                              </Form.Group>
                            </Row>
                          </div>
                        )}
                      </Box>
                    ) : (
                      activeStep === 1 && (
                        <FormPagosReferencias
                          metodoPago={metodoPago}
                          setMetodoPago={setMetodoPago}
                          // generarPresupuesto={() => {
                          //   //GenerarLigaPago("presupuesto")
                          //   setTipoAccion(tipo.PRESUPUESTO);

                          //   // calcularServicioSei();
                          // }}
                          continuarPago={setContinuarPago}
                        />
                      )
                    )}
                  </React.Fragment>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                {activeStep === steps.length - 1 && (
                  <ButtonB
                    variant="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Anterior
                  </ButtonB>
                )}
                <Box sx={{ flex: "1 1 auto" }} />

                <ButtonB
                  onClick={async () => {
                    if (activeStep === steps.length - 1) {

                      if (metodoPago === "pago1") {
                        setTipoAccion(tipo.PAGO);
                        setLoadingPago(true);
                        setCallPago(!callPago)
                        //generarTransaccion(tipo.PAGO)
                      }
                      return;
                    }
                    if (datos.datosGenerales.total === 0) {
                      setAdvertenciaPago(true);

                      return;
                    }
                    await handleNext();
                  }}
                  variant="secondary"
                  disabled={
                    calculoData ||
                    !formik.isValid ||
                    (activeStep === steps.length - 1 && continuarPago === false)
                  }
                >
                  {activeStep === steps.length - 1
                    ? metodoPago === "pago1"
                      ? "Continuar al pago"
                      : "Descargar referencia de pago"
                    : calculoData?.calculos?.total >= 0
                      ? "Realizar pago"
                      : "Realizar pago"}
                </ButtonB>
              </Box>
            </Form>
          </View>
        )}
      </View>

      <ModalActions
        title={"Hubo un problema"}
        content={"por favor, vuelve a intentarlo"}
        icon={"error"}
        desc={errorMessage}
        show={pagoFallidoModal}
        onHide={() => {
          setPagoFallidoModal(false);
          setErrorMessage("");
        }}
        buttonAction={() => {
          setPagoFallidoModal(false);
          setErrorMessage("");
        }}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPago}
      >
        <h1>Esperando Respuesta</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

