import React from 'react'
import { AccountSettings, View, Alert } from '@aws-amplify/ui-react';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('es')
I18n.putVocabulariesForLanguage('es', {
  'Username cannot be empty': 'El nombre de usuario no puede estar vacío',
    'Password cannot be empty': 'La contraseña no puede estar vacía',
    'password is required to signIn': 'La contraseña no puede estar vacía',
    'User does not exist.': 'El usuario no existe',
    'Incorrect username or password.': 'Usuario o Contraseña incorrectos',
    'Attempt limit exceeded, please try after some time.': 'Se superó el límite de intentos. Inténtelo más tarde.',
});

export const ChangePassword = () => {
    const navigate = useNavigate();
    const handleSuccess = () => {
        toast.success('Se realizó el cambio con éxito', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            navigate("/Configuracion")
    }
    
    const handleError = () => {
        toast.error('Error al cambiar la contraseña', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }
    const translations = {
        'Incorrect username or password.': 'Contraseña incorrecta.',
        'Attempt limit exceeded, please try after some time.': 'Se excedió el límite de intentos. Inténtalo más tarde.',
        'Current password is incorrect': 'La contraseña actual es incorrecta.',
        'Password confirmation does not match': 'La confirmación de la contraseña no coincide.',
        'An unknown error occurred': 'Ocurrió un error desconocido.',
      };
      const translateErrorMessage = (message) => {
        return translations[message] || message;
      };
      
      const components = {
        ErrorMessage: ({ children }) => {
          // Traduce el mensaje recibido
          const translatedMessage = translateErrorMessage(children);
      
          return (
            <Alert variation="error">
              {translatedMessage}
            </Alert>
          );
        },
      };
      
    return (
        <div className="FormTramite">
            <View className="FormTitle">Configuración de Cuenta</View>
            <View className='FormDescripcion'>Actualiza tu información</View>
            <Row className='mb-0'>
                <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                    <button type="button" onClick={() => navigate("/Configuracion")}
                        className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                        <FaArrowLeft /> Volver al Inicio
                    </button>
                </Form.Group>

                <Form.Group as={Col} md="6">
                </Form.Group>
                <Form.Group className='d-grid gap-2' as={Col} md="3">
                </Form.Group>
            </Row>
            <div className='ViewActa mt-3'>

                <AccountSettings.ChangePassword onSuccess={handleSuccess} components={components} displayText={{
                    currentPasswordFieldLabel: "Contraseña Actual",
                    newPasswordFieldLabel: "Contraseña Nueva",
                    confirmPasswordFieldLabel: "Confirmar Contraseña",
                    updatePasswordButtonText: "Guardar Cambios"
                }} 
                
                />
            </div>
            <ToastContainer/>
        </div>
    )
}
