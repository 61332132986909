import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserAttributes } from 'aws-amplify/auth';

const PhoneVerificationGuard = ({ isActive, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isActive === "INACTIVE") return;

    const checkPhoneVerificationAndRedirect = async () => {
      try {
        // Obtener atributos del usuario
        const userAttributes = await fetchUserAttributes();

        // Verificar si el número de teléfono está confirmado
        

        if (userAttributes.phone_number_verified !== 'true') {
          // Redirigir si no está verificado
          navigate('/Configuracion/VerifyNumberPhone');
        }
      } catch (error) {
        console.error('Error al verificar el estado del número de teléfono:', error);
      }
    };

    checkPhoneVerificationAndRedirect();
  }, [isActive,navigate]);

  return children;
};

export default PhoneVerificationGuard;
