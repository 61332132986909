import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import { translations } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';

import ls from "@livesession/sdk";
/* import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://35836a7f96d3a36c40df658f1e42637e@o4508576239452160.ingest.us.sentry.io/4508576245022720",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */

// const REACT_APP_LIVESESSION_ID = process.env.REACT_APP_LIVESESSION_ID
// eslint-disable-next-line no-unused-vars
const REACT_APP_LIVESESSION_ID = "a1aa3fce.4ec80389"

//  ls.init(REACT_APP_LIVESESSION_ID);
// ls.newPageView(); 
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  console.info = function () {};
  console.debug = function () {};
}

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
          <App />
        
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
