import * as React from 'react';
import { View } from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import PasswordIcon from '@mui/icons-material/Password';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import { Divider } from '@mui/material';
import { Form, Row, Col } from 'react-bootstrap'
import { FaArrowLeft } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md";
import { useEffect, useState } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
export const ConfigCuenta = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const obtenerInfo = async () => {
      const user = await fetchUserAttributes();
      setUserInfo(user)

      console.log(user)
    }
    obtenerInfo()
  }, [])
  return (
    <>
      <div className="FormTramite">
        <View className="FormTitle">Configuración de Cuenta</View>
        <View className='FormDescripcion'>Actualiza tu información</View>
        <Row className='mb-0'>
          <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
            <button type="button" onClick={() => navigate("/tramites-servicios")}
              className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
              <FaArrowLeft /> Volver al Inicio
            </button>
          </Form.Group>

          <Form.Group as={Col} md="6">
          </Form.Group>
          <Form.Group className='d-grid gap-2' as={Col} md="3">
          </Form.Group>
        </Row>

        <div className='ViewActa mt-3'>

          <List component="nav" aria-label="main mailbox folders">
            <ListItemButton

              onClick={() => navigate("/Configuracion/ChangeName")}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Cambiar Nombre de Usuario" />
            </ListItemButton>
            <Divider />
            <ListItemButton

              onClick={() => navigate("/Configuracion/ChangeEmail")}
            >
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary="Cambiar Correo" />
              {userInfo.email_verified === "true" &&

                <ListItemIcon>


                  <label>Verificado</label>
                  <MdVerifiedUser />

                </ListItemIcon>
              }
            </ListItemButton>
            <Divider />
            <ListItemButton

              onClick={() => navigate("/Configuracion/ChangeNumberPhone")}
            >
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText primary="Cambiar Número de Teléfono" />
              {userInfo.phone_number_verified === "true" &&

                <ListItemIcon>


                  <label>Verificado</label>
                  <MdVerifiedUser />

                </ListItemIcon>
              }
            </ListItemButton>
            <Divider />
            <ListItemButton

              onClick={() => navigate("/Configuracion/ChangePassword")}
            >
              <ListItemIcon>
                <PasswordIcon />
              </ListItemIcon>
              <ListItemText primary="Cambiar Contraseña" />
            </ListItemButton>
            <Divider />
          </List>

        </div>


      </div>
    </>
  )
}

