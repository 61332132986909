import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormDatosGenerales } from "./FormDatosGenerales"
import { FormDatosDomicilio } from './FormDatosDomicilio';
import { FormDatosContacto } from './FormDatosContacto';
import { FormDatosMedicos } from './FormDatosMedicos';
import { FormDatosIdentidad } from './FormDatosIdentidad';
import { FormDatosEnvio } from './FormDatosEnvio';
import { FormDatosEmergencia } from './FormDatosEmergencia';
import { FormDocumentos } from './FormDocumentos';
import { useTramiteDataGeneral } from '../../TramiteContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { FcOk } from "react-icons/fc";
import { toast, ToastContainer } from 'react-toastify';
import ObtenerDescripciones from './ObtenerDescripciones';
import { CrearIteamAPI } from '../../api/Licencias';




export const TramiteLicencias = () => {
    const navigate = useNavigate();
    const { getJsonFromLocalStorage } = useTramiteDataGeneral();
    const dataLicencias = getJsonFromLocalStorage("DatosLicencias")
    const [dataL, setDataL] = useState("")
    const [loading, setLoading] = useState(true);
    const [changeData, setChangeData] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
     
   
        const raw = JSON.stringify({
            "procedureType": dataLicencias.procedureType,
            "id": dataLicencias.id
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://chdzv41shb.execute-api.us-east-1.amazonaws.com/master/procedures/getProcedure", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setDataL(result.item)
                console.log(dataL);
                setLoading(false);
            })
            .catch((error) => console.error(error));
    }, [changeData])
    const handleTerminar = (id) => {
        setDisabledButton(true);
        setLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": dataL.procedureType,
            "id": id,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://chdzv41shb.execute-api.us-east-1.amazonaws.com/master/ticket/createTicket", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.error !== undefined) {
                    toast.error(result.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setDisabledButton(false);
                    setLoading(false)
                } else {
                    toast.success("Datos Guardados Correctamente", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate("/tramite/DPC-Licencias/Historial")
                    setDisabledButton(false);
                    setLoading(false)
                }
            })
            .catch((error) => console.error(error));
    }
    const handleActualizarTicket = (id) => {

        const data = {
            "procedure": dataL.procedure,
            "id": id,
            "type":dataL.type,
            "finishEdit": true
        };
        CrearIteamAPI(data).then(result => {
            console.log(result)
            if (result.ok) {
                toast.success("Datos Guardados Correctamente", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/tramite/DPC-Licencias/Historial")
                setDisabledButton(false);
                setLoading(false)
            } else {

                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch(error => {
            console.log(error)
        })
    }
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Trámite de Licencias</View>
                <View className='FormSubTitle'>Datos del Contribuyente</View>
                <View className="FormDescripcionFlotillas">
                    <div className="InfoFlotillas">
                        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>Tipo de Licencia:</strong>
                            </span>

                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>Tipo de Trámite:</strong>{" "}
                            </span>
                            {dataL.estatus === "Rechazado" &&
                                <span style={{ display: "flex", justifyContent: "center" }}>
                                    <strong>Mensaje:</strong>{" "}
                                </span>
                            }


                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                            <span style={{ display: "flex", justifyContent: "center" }}>

                                <ObtenerDescripciones id={"LICENCIAS"} valor={"categoria"} campo={dataLicencias.procedure} />
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <ObtenerDescripciones id={16} campo={dataLicencias.type} />
                            </span>
                            {dataL.estatus === "Rechazado" &&
                                <span style={{ display: "flex", justifyContent: "center", backgroundColor: "yellow" }}>
                                    {dataL.feedback[0].comentario}

                                </span>
                            }

                        </div>
                    </div>
                </View>
            </div>
            {loading ?
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <h1>Cargando Registros</h1>
                    <CircularProgress color="inherit" />
                </Backdrop>

                :


                <View className="ViewPrincipal">
                    <Row className='mb-0'>
                        <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                            <button type="button" onClick={() => navigate("/tramite/DPC-Licencias/Historial")}
                                className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                                <FaArrowLeft /> Volver al Inicio
                            </button>
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                        </Form.Group>
                        <Form.Group className='d-grid gap-2' as={Col} md="3">
                        </Form.Group>
                    </Row>
                    <View className="ViewContent">
                        <View className='ViewActa'>

                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos Generales
                                        </label>
                                        {dataL.datosGenerales !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <FormDatosGenerales datos={dataL} setChangeData={setChangeData} changeData={changeData} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Residencia
                                        </label>
                                        {dataL.domicilioResidencia !== null && dataL?.domicilioResidencia?.isComplete === true &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosDomicilio datos={dataL} setChangeData={setChangeData} changeData={changeData} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Envío
                                        </label>
                                        {dataL.direccionEnvio !== null && dataL?.direccionEnvio?.isComplete === true &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosEnvio datos={dataL} setChangeData={setChangeData} changeData={changeData} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Contacto
                                        </label>
                                        {dataL.datosContacto !== null && dataL?.datosContacto?.otpData?.verificado === true && dataL?.datosContacto?.isComplete === true &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosContacto datos={dataL} setChangeData={setChangeData} changeData={changeData} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Media Filiación
                                        </label>
                                        {dataL.mediaFiliacion !== null && dataL?.mediaFiliacion?.isComplete === true &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosMedicos datos={dataL} setChangeData={setChangeData} changeData={changeData} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel8-content"
                                    id="panel8-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Datos de Emergencia
                                        </label>
                                        {dataL.contactoEmergencia !== null && dataL?.contactoEmergencia?.isComplete === true &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosEmergencia datos={dataL} setChangeData={setChangeData} changeData={changeData} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel9-content"
                                    id="panel9-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Documentación
                                        </label>
                                        {dataL.documentos !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDocumentos datos={dataL} setChangeData={setChangeData} changeData={changeData} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className='mb-2' style={{ borderRadius: "5px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5-content"
                                    id="panel5-header"
                                    style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}
                                >
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ textDecoration: "underline", fontSize: "x-large", backgroundColor: "#B0C7B8", borderRadius: "5px" }}>
                                            Verificar Identidad
                                        </label>
                                        {dataL.identidad !== null &&

                                            <FcOk />
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormDatosIdentidad datos={dataL} setChangeData={setChangeData} changeData={changeData} />
                                </AccordionDetails>
                            </Accordion>
                            {dataL.isComplete === true && (dataL.estatus === "Captura" || dataL.estatus === "Rechazado") ?
                                dataL.ticketID !== undefined ?
                                    <Row className='m-1'>
                                        <Button variant='secondary' disabled={disabledButton} onClick={() => { handleActualizarTicket(dataL.id) }}>Actualizar Información</Button>

                                    </Row>

                                    :

                                    <Row className='m-1'>
                                        <Button variant='secondary' disabled={disabledButton} onClick={() => { handleTerminar(dataL.id) }}>Terminar Proceso</Button>

                                    </Row>
                                : null

                            }
                        </View>
                    </View>
                </View>
            }
            <ToastContainer />
        </>
    )
}