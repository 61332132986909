import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import {getCurrentUser,fetchUserAttributes} from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import Row from 'react-bootstrap/Row';
import { useFormik } from 'formik';
import { publishMessage } from '../../graphql/mutations';
import * as yup from 'yup';
import { Button as ButtonB } from 'react-bootstrap';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
import { onMessageReceived } from '../../graphql/subscriptions';
import { useTramiteDataGeneral } from '../../TramiteContext'
import {
  Button,
  View,
} from "@aws-amplify/ui-react";
import { isMobile } from 'react-device-detect';
import { Backdrop, CircularProgress } from '@mui/material';
import ModalActions from './ModalActions';
import imagenTarjeta from '../../assets/mensaje_tarjeta.png'
import PantallaExitoImpuestos from '../PantallaExitoImpuestos';
import { ToastContainer, toast } from 'react-toastify';
import { FormArrendamiento2 } from './FormArrendamiento2';
import Iframe from 'react-iframe';
import ResumenCard from './ResumenCard';
import { CalcularImpuesto, ObtenerToken } from '../../api/Impuestos';
const steps = ['Información', 'Información complementaria', "Pago"];
const ENDPOINT_SEI = process.env.REACT_APP_SEI_API_ENDPOINT;

const client = generateClient();
export const FormArrendamiento = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [metodoPago, setMetodoPago] = useState('pago1');
  const [info, setInfo] = React.useState("");
  const [infoCal, setInfoCal] = React.useState(null);
  const [taskToken, setTaskToken] = useState('')
  const { setData, setCalculo, calculo } = useTramiteDataGeneral()
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [loadingPago, setLoadingPago] = useState(false);
  const [continuarPago, setContinuarPago] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [idForm, setIdForm] = useState('Not Assign');
  const [pagoExitoModal, setPagoExitoModal] = useState(false)
  const [pagoFallidoModal, setPagoFallidoModal] = useState(false)
  const [ligaPagoMit, setLigaPagoMit] = useState(null)
  const [pantallaExito, setPantallaExito] = useState(false)
  const [respuestaPago, setRespuestaPago] = useState({});
  const [respuestaRecibo, setRespuestaRecibo] = useState({})
  useEffect(() => {
    generateID()
  }, [])
  const generateID = async () => {
    
    const user = await getCurrentUser();
  
    setIdForm(`InscImpuestoISR_${user.username}_${Date.now()}`)
  }

  const validationSchema = yup.object().shape({
    rfcEnajenante: yup.string().required("Este Campo es Obligatorio").matches(/^([A-Z\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido').min(12),
   /*  rfcAdquiriente: yup.string().required("Este Campo es Obligatorio").matches(/^([A-Z\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido').min(12),
    nombreAdquiriente: yup.string().required("Este Campo es Obligatorio"), */
    curpEnajenante: yup.string().required("Este Campo es Obligatorio").min(18, "CURP no valida"),
    nombreEnajenante: yup.string().required("Este Campo es Obligatorio"),
    rfcNotario: yup.string().required("Este Campo es Obligatorio").matches(/^([A-Z\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido').min(12),
    curpNotario: yup.string().required("Este Campo es Obligatorio").min(18, "CURP no valida"),
    nombreNotario: yup.string().required("Este Campo es Obligatorio"),
    numNotaria: yup.string().required("Este Campo es Obligatorio"),
    numEscritura: yup.string().required("Este Campo es Obligatorio"),
    fechaFirma: yup.date().required("Este Campo es Obligatorio"),
    municipio: yup.string().required("Este Campo es Obligatorio"),
    declaracion: yup.string().required("Este Campo es Obligatorio"),
    numComplementaria: yup.string(),
    fechaAnteriorDec: yup.string(),
    correccionFiscal: yup.boolean(),

  });
  const formik = useFormik({
    validationSchema,
    onSubmit: values => {
      setInfo(values);
      handleNext()
    },
    initialValues: {
      rfcEnajenante: '',
      /* rfcAdquiriente: '',
      nombreAdquiriente: '', */
      curpEnajenante: '',
      nombreEnajenante: '',
      rfcNotario: '',
      curpNotario: '',
      nombreNotario: '',
      numNotaria: "",
      numComplementaria: "",
      fechaAnteriorDec: "",
      correccionFiscal: false,
      numEscritura: '',
      fechaFirma: '',
      deducciones: '',
      municipio: '',
      declaracion: '',

    }

  });
  useEffect(() => {
    ObtenerToken().then(result => {
      if (result.ok === true) {
        setToken(result.message.token)
      } else {
        toast.error(result.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }).catch(error => {
      console.log(error)
    })
  }, []);
  const CalcularArrendamiento = (generarEstado = false) => {
    setLoadingPago(true);
    setData(infoCal)
    const requestData = {
      "padron": {
        "id": 4,
        "clave": "SERV678"
      },
      "generaEstadoCuenta": generarEstado,
      "declaraciones": {
        "enajenante": {
          "rfcEnajenante": info.rfcEnajenante,
          "curpEnajenante": info.curpEnajenante,
          "nombreEnajenante": info.nombreEnajenante
        },
        "notario": {
          "rfcNotario": info.rfcNotario,
          "curpNotario": info.curpNotario,
          "nombreNotario": info.nombreNotario,
          "numNotaria": info.numNotaria
        },
        "escritura": {
          "numEscritura": info.numEscritura,
          "fechaFirma": info.fechaFirma,
          "municipio": info.municipio
        },
        "complementaria": info.declaracion === 2 ? true : false,
        "tipoDeclaracionDatos": {
          "numComplementaria": info?.numComplementaria,
          "fechaAnteriorDec": info?.fechaAnteriorDec,
          "correccionFiscal": info?.correccionFiscal
        },
        "datosDeclaracion": {
          "ingresosEnajenacion": infoCal.ingresosEnajenacion,
          "deducciones": infoCal.deducciones,
          "aniosAdquisicionVenta": infoCal.aniosAdquisicionVenta,
          "actualizacion": infoCal.actualizacion === "" ? "0" : infoCal.actualizacion,
          "recargos": infoCal.recargos === "" ? "0" : infoCal.recargos,
          "actualizacionFederacion": infoCal.actualizacionFederacion === "" ? "0" : infoCal.actualizacionFederacion,
          "recargosFederacion": infoCal.recargosFederacion === "" ? "0" : infoCal.recargosFederacion,
          "multaCorreccionAnterior": infoCal.multaCorreccionAnterior === "" ? "0" : infoCal.multaCorreccionAnterior,
          "montoPagadoAnterior": infoCal.montoPagadoAnterior === "" ? "0" : infoCal.montoPagadoAnterior
        }
      }
    };

    CalcularImpuesto(requestData, token).then(result => {
      if (result.ok) {
       
        setCalculo(result.message.response);
        setLoadingPago(false);
      } else {
        setLoadingPago(false);
        toast.error(result.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }).catch(error => {
      setLoadingPago(false);
      console.log(error)
    })
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
    const year = today.getFullYear(); // Get the full year (4 digits)

    return `${day}/${month}/${year}`;
  }


  const formatearFecha2 = (fecha) => {
    if (fecha) {
      const date = new Date(fecha);
      const day = String(date.getDate()+1).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return null;
  };
 
  const handleNext = async () => {
    await generateID(); 
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };


  const generarTransaccion = async () => {
  
    const conceptos = calculo.conceptos?.map(cp => {
      return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
    })
    const user = await fetchUserAttributes();
    try {
      const transaccionEndpoint = `${ENDPOINT_SEI}/sei/putEvent`;

      const response = await fetch(transaccionEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          id: idForm,
          payload: {
            "validationRequested": true,

            "data": {
              "rfc": info.rfcEnajenante,
              "email": user.email,
              "clavePadron": "SERV678",
              "padron": {
                "nombrePadron": "ENAJENACIÓN DE BIENES INMUEBLES" // ?
              },
              "tipoDeclaracion": info.declaracion === "1" ? "NORMAL" : "COMPLEMENTARIA",
              "user": user.name !== undefined ? user.name : "Contribuyente",
              "estadoCuenta": {
                "serie": calculo?.estadoCuenta?.serie,
                "identificador": calculo?.estadoCuenta?.identificador,
                "tramiteId": calculo?.estadoCuenta?.tramiteId,
                "loteId": calculo?.estadoCuenta?.loteId,
                "fechaVencimiento": calculo?.estadoCuenta?.fechaVencimiento,
                "total": calculo.calculos?.total // -- cambio
              },
              "referencia": `${calculo.estadoCuenta?.referencias[0].referencia}`,
              "serie": calculo?.estadoCuenta?.serie,
              "conceptos": conceptos,
              "razonSocial": info.nombreEnajenante,
              
            },
            authToken: token,
            userId: user.sub,
            type: "declaraciones",

          }

        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        // Manejar el error según tus necesidades
        console.error("Error en la transacción:", errorData.error);
        return;
      }

      const responseData = await response.json();
      // Manejar la respuesta según tus necesidades
      console.log(responseData)
      return responseData
    } catch (error) {

      console.error("Error al realizar la transacción:", error.message);
      return error
    }
  };


  const GenerarLigaPago = async (tipoPago = null) => {
    setLoading(true)
    const user = await fetchUserAttributes();


    // metodoPago : pago1 : card - pago2 : - pago3 :
    // prepara conceptos con lo de calculo
    const opcionPago = tipoPago ? tipoPago : metodoPago
    const conceptos = calculo.conceptos?.map(cp => {
      return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
    })
    //preparar otras referencias
    const otrasRef = calculo.estadoCuenta?.referencias?.map(referencia => {
      const partes = referencia.recaudacion.split('-');
      const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
      const convenio = parteDespuesDelGuion
      return { banco: referencia.recaudacion, convenio: convenio ? convenio : '', referencia: referencia.referencia }
    })
    if (opcionPago === "pago1" || opcionPago === "pago2" || opcionPago === "pago3" || opcionPago === "pago4") {
      generarTransaccion()
    }
    const variables = opcionPago === "pago1" ?
      {
        "id": idForm,
        "payload": {
          "paymentMethod": "card",
          "email": user.email,
          "user": user.name !== undefined ? user.name : "Contribuyente",
          "tramite": "ISR- ENAJENACION DE BIENES INMUEBLES",
          "reference": `${calculo.estadoCuenta?.referencias[0].referencia}`,//"202300011471739766260",
          "monto": `${calculo.calculos?.total}`,//"0.01",
          taskToken,
          type: "declaraciones",
        }
      }
      :
      opcionPago === "pago2" || opcionPago === "pago3" || opcionPago === "pago4" ?
        {
          "id": idForm,
          "payload": {

            "paymentMethod": "spei",
            "data": {
              "user": user.name !== undefined ? user.name : "Contribuyente",
              "loteId": `${calculo.estadoCuenta?.loteId}`,
              "total_pagar": calculo.calculos?.total,
              "tramite": "ISR- ENAJENACION DE BIENES INMUEBLES",//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
              "movimiento": "ISR- ENAJENACION DE BIENES INMUEBLES",
              "rfc": info.rfcEnajenante,
              "fecha_generacion": getCurrentDate(),
              "fecha_vigencia": `${formatearFecha2(calculo.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
              "contribuyente": info.nombreEnajenante,
              "referencia": `${calculo.estadoCuenta?.referencias[0].referencia}`,
              "otras_referencias": otrasRef,
              "periodo": "",

            },
            "email": user.email,
            "procedure": "referencia_pago"
          }
        } 

        : opcionPago === "Formato" ? 
        {
          "id": idForm,
          "payload": {

            "paymentMethod": "spei",
            "data": {
              "user":  user.name !== undefined ? user.name : "Contribuyente",
              "rfcEnajenante":info?.rfcEnajenante,
              "curpEnajenante": info?.curpEnajenante,
              "nombreEnajenante": info?.nombreEnajenante,
              "tipo_declaracion": info?.declaracion === "1" ? "Normal" : "Complementaria",
              "numComplementaria": info?.numComplementaria,
              "fechaAnteriorDec": info?.fechaAnteriorDec,
              "gananciaObtenida": calculo.calculos?.gananciaObtenida,
              "totalISREnajenacionBienesInmueblesART126LISR": calculo.calculos?.totalISREnajenacionBienesInmueblesART126LISR,
              "isr5PorcientoEnajenacionBienesInmueblesEstado": calculo.calculos?.isr5PorcientoEnajenacionBienesInmueblesEstado,
              "actualizacion": calculo.calculos?.actualizacion,
              "recargos": calculo.calculos?.recargos,
              "subtotalArticulo127": calculo.calculos?.subtotalArticulo127,
              "enajenacionBienesInmueblesFederacion": calculo.calculos?.enajenacionBienesInmueblesFederacion,
              "actualizacionFederacion": calculo.calculos?.actualizacionFederacion,
              "recargosFederacion": calculo.calculos?.recargosFederacion,
              "subtotalArticulo126": calculo.calculos?.subtotalArticulo126,
              "multaPorCorrecion": calculo.calculos?.multaPorCorrecion,
              "cantidadACargo": calculo.calculos?.cantidadACargo,
              "montoPagadoConAnterioridad":calculo.calculos?.montoPagadoConAnterioridad,
              "total": calculo.calculos?.total,
              "saldoAFavor": calculo.calculos?.saldoAFavor,
              "rfcNotario": info?.rfcNotario,
              "numNotaria": info?.numNotaria,
              "curpNotario": info?.curpNotario,
              "nombreNotario": info?.nombreNotario,
              "numEscritura": info.numEscritura,
              "fechaFirma": info?.fechaFirma,
              "municipio": info?.municipio

            },
            "email": user.email,
            "procedure": "enajenacion_isr"
          }
        } 

        :
        {
          "id": idForm,
          "payload": {
            "paymentMethod": "spei",
            "data": {
              "user": user.name !== undefined ? user.name : "Contribuyente",
              "contribuyente": info.nombreEnajenante,
              "fecha_generacion": getCurrentDate(),
              "rfc": info.rfcEnajenante,
              "fecha_vigencia": `${formatearFecha2(calculo.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
              "movimiento": "ISR- ENAJENACION DE BIENES INMUEBLES",
              "tramite": "ISR- ENAJENACION DE BIENES INMUEBLES",//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
              "instrumento_pago": "referencia bancaria",
              "base_impuesto": calculo?.calculos?.gananciaBaseImpuesto,//"5%",
              "periodo": ``,
              "monto_anterior": calculo?.calculos?.montoPagadoConAnterioridad,
              "tipo_declaracion": info.declaracion === "1" ? "Normal" : "Complementaria",
              "otros_conceptos": conceptos,
              "total_pagar": `${calculo.calculos?.total}`
            },
            "email": user.email,
            "procedure": "presupuesto_bienes_inmuebles"

          },
        };
    const Data = await client.graphql({
      query: publishMessage,
      variables: {
        ...variables,
        payload: JSON.stringify(variables.payload),
      },
    });
  }

  const [message, setMessage] = useState(null);
  useEffect(() => {
    const subscription = client.graphql({ query: onMessageReceived, variables: { id: idForm } })
      .subscribe({
        next: (event) => {
          console.log("------EVENT",event)
          const receivedMessage = event.data.onMessageReceived;
          //parse json on payload response. - authToken - taskToken --- rfc
          setMessage(receivedMessage);
          const dataRec = JSON.parse(receivedMessage.payload)

          if (dataRec.taskToken) {
            setTaskToken(dataRec.taskToken)
          }
          if (dataRec?.error) {
            setErrorMessage(dataRec?.error)
            setPagoFallidoModal(true)
            if (dataRec.step === "validarRfc") {
           
              setLoading(false)
            }
            //alert("Hubo un error intentalo de nuevo")
            return
          }
          if (dataRec.status === "PAGO VALIDADO") {
            //activar loader

            setRespuestaPago(dataRec)
            setLoadingPago(true)

          }
          if (dataRec?.procedure === "paymentUrl") {
            if (dataRec.response.cd_response === "error") {
              //AlertsAction("error")
              setPagoExitoModal(false)
              setPagoFallidoModal(true)
              setLoadingPago(false);
              setLoading(false)
              return
            }
            if (dataRec.response.cd_response === "success") {
              //AlertsAction("success")
              //setPagoExitoModal(true)
              setPagoFallidoModal(false)
              setLigaPagoMit(dataRec.response.nb_url)
              setLoadingPago(false);
              setLoading(false);
            }
          }
          if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_bienes_inmuebles" || dataRec.Procedure === "recibo_pago" || dataRec.Procedure === "enajenacion_isr") {
            if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_bienes_inmuebles" || dataRec.Procedure === "enajenacion_isr") {
              window.open(dataRec.TXTBody);
              setLoadingPago(false)
            }
            if (dataRec.Procedure === "recibo_pago") {
              //activar vista de exito
              setPantallaExito(true)
              setRespuestaRecibo(dataRec)
              setLoadingPago(false)
              setLoading(false)
              return
            }
            if (dataRec.Procedure === "referencia_pago") {
              setLoadingPago(false)
              setLoading(false)
              setPagoExitoModal(true)
              setPagoFallidoModal(false)
              return
            }/* else {
              setCallFinal(true)
            }*/
            return
          }
        },
        error: (error) => {
          console.error('Subscription error', error);
        },
      });


    return () => {
      subscription.unsubscribe();
    };
  }, [idForm]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;

    formik.setFieldValue(name, value.toUpperCase())


  };
  const handleInputChangeCodigo = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    formik.setFieldValue(name, numericValue);
  };
  return (
    <>
      <div className="HeaderTramite">
        <View className="FormTitle">ISR- Enajenación de bienes inmuebles{" "}</View>
        <View className="FormDescripcion">
          Este trámite consiste en el pago del impuesto que grava las ganancias
          obtenidas por la venta de propiedades. La tasa es del 5% sobre la
          ganancia, se calcula restando el costo de adquisición y los gastos
          relacionados con la transacción del precio de venta.
        </View>
      </div>

      <View className="ViewPrincipal">
        {
          pantallaExito ?
            <PantallaExitoImpuestos pago={respuestaPago} recibo={respuestaRecibo} GenerarLigaPago={GenerarLigaPago}  titulo={'ISR- Enajenación de bienes inmuebles'} />
            :
            ligaPagoMit ?
              <View >
                <div className='w-full flex justify-end'><img width={'300px'} height={'150px'} src={imagenTarjeta} alt="Mensaje tarjeta" /></div>
                <Iframe
                  src={ligaPagoMit}
                  height="850px"
                  width={isMobile ? "99%" : "550px"}
                  display="initial"
                  styles={{ overflow: "hidden" }}
                  position="relative"
                />
              </View>
              :
              <View className="FormContent">
                <Form onSubmit={formik.handleSubmit} className="ViewActa">

                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep} className='mb-2'>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          <label>
                            Descripcion: Pulsa el boton para generar tu orden de
                            pago.
                          </label>
                        </Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                          style={{ justifyContent: "space-between" }}
                        >
                          <Button
                            variant="secondary"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Anterior
                          </Button>

                          <Button type="submit" variant="secondary">
                            Enviar
                          </Button>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {activeStep === 0 ? (
                          <Box>

                            <Row className="mb-3 mt-3">
                              <Form.Group as={Col} className="position-relative">
                                <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                                  Datos del Enajenante
                                </p>
                                <Form.Label>RFC con Homoclave*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="rfcEnajenante"
                                  placeholder='RFC'
                                  maxLength={13}
                                  value={formik.values.rfcEnajenante}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.rfcEnajenante && !formik.errors.rfcEnajenante}
                                  isInvalid={!!formik.errors.rfcEnajenante && formik.touched.rfcEnajenante}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.rfcEnajenante}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3 mt-3">
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>CURP*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="curpEnajenante"
                                  maxLength={18}
                                  placeholder='CURP'
                                  value={formik.values.curpEnajenante}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.curpEnajenante && !formik.errors.curpEnajenante}
                                  isInvalid={formik.touched.curpEnajenante && !!formik.errors.curpEnajenante}
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.curpEnajenante}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>
                                Apellido Paterno, Materno Y Nombre(s) O Denominación Social*
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="nombreEnajenante"
                                  placeholder="Nombre (s)"
                                  value={formik.values.nombreEnajenante}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.nombreEnajenante && !formik.errors.nombreEnajenante}
                                  isInvalid={formik.touched.nombreEnajenante && !!formik.errors.nombreEnajenante}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.nombreEnajenante}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                   {/*          <Row className="mb-3">
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>
                                  Denominación Social
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="denominacionSocial"
                                  placeholder="Denominación Social"
                                  value={formik.values.denominacionSocial}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.denominacionSocial && !formik.errors.denominacionSocial}
                                  isInvalid={formik.touched.denominacionSocial && !!formik.errors.denominacionSocial}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.denominacionSocial}</Form.Control.Feedback>
                              </Form.Group>
                            </Row> */}
                           {/*  <Row className="mb-3 mt-3">
                              <Form.Group as={Col} className="position-relative">
                                <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                                  Datos del Adquiriente
                                </p>
                                <Form.Label>RFC con Homoclave*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="rfcAdquiriente"
                                  placeholder='RFC'
                                  maxLength={13}
                                  value={formik.values.rfcAdquiriente}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.rfcAdquiriente && !formik.errors.rfcAdquiriente}
                                  isInvalid={!!formik.errors.rfcAdquiriente && formik.touched.rfcAdquiriente}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.rfcAdquiriente}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label> Apellido Paterno, Materno Y Nombre(s)*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="nombreAdquiriente"
                                  placeholder="Nombre (s)"
                                  value={formik.values.nombreAdquiriente}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.nombreAdquiriente && !formik.errors.nombreAdquiriente}
                                  isInvalid={formik.touched.nombreAdquiriente && !!formik.errors.nombreAdquiriente}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.nombreAdquiriente}</Form.Control.Feedback>
                              </Form.Group>
                            </Row> */}
                            <Row className="mb-3 mt-3">
                              <Form.Group as={Col} className="position-relative">
                                <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                                  Datos del Notario
                                </p>
                                <Form.Label>RFC con Homoclave*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="rfcNotario"
                                  placeholder='RFC'
                                  maxLength={13}
                                  value={formik.values.rfcNotario}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.rfcNotario && !formik.errors.rfcNotario}
                                  isInvalid={!!formik.errors.rfcNotario && formik.touched.rfcNotario}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.rfcNotario}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3 mt-3">
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>CURP*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="curpNotario"
                                  maxLength={18}
                                  placeholder='CURP'
                                  value={formik.values.curpNotario}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.curpNotario && !formik.errors.curpNotario}
                                  isInvalid={formik.touched.curpNotario && !!formik.errors.curpNotario}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.curpNotario}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label> Apellido Paterno, Materno Y Nombre(s)*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="nombreNotario"
                                  placeholder="Nombre (s)"
                                  value={formik.values.nombreNotario}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.nombreNotario && !formik.errors.nombreNotario}
                                  isInvalid={formik.touched.nombreNotario && !!formik.errors.nombreNotario}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.nombreNotario}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label> Número de la Notaría*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="numNotaria"
                                  placeholder='Número de la Notaría'
                                  value={formik.values.numNotaria}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.numNotaria && !formik.errors.numNotaria}
                                  isInvalid={!!formik.errors.numNotaria && formik.touched.numNotaria}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.numNotaria}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>

                            <Row className="mb-3 mt-3">
                              <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                                Datos de la Escritura
                              </p>
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>Número de la Escritura*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="numEscritura"
                                  placeholder='Número de la Escritura'
                                  value={formik.values.numEscritura}
                                  onChange={handleInputChange}
                                  isValid={formik.touched.numEscritura && !formik.errors.numEscritura}
                                  isInvalid={!!formik.errors.numEscritura && formik.touched.numEscritura}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.numEscritura}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="position-relative">
                                <Form.Label>
                                  Fecha de Firma de la Escritura*
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  name="fechaFirma"
                                  value={formik.values.fechaFirma}
                                  onChange={formik.handleChange}
                                  isValid={formik.touched.fechaFirma && !formik.errors.fechaFirma}
                                  isInvalid={formik.touched.fechaFirma && !!formik.errors.fechaFirma}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.fechaFirma}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} controlId="validationFormik102" className="position-relative">
                                <Form.Label> Municipio*</Form.Label>
                                <Form.Select
                                  name="municipio"
                                  value={formik.values.municipio}
                                  onChange={formik.handleChange}
                                  isValid={formik.touched.municipio && !formik.errors.municipio}
                                  isInvalid={!!formik.errors.municipio && formik.touched.municipio} //verificar bajo que condcion se valida
                                >
                                  <option value="">Seleccione...</option>
                                  <option value="DURANGO">Durango</option>
                                  <option value="GOMEZ PALACIO">Gómez Palacio</option>
                                  <option value="LERDO">Lerdo</option>
                                  <option value="CANATLAN">Canatlán</option>
                                  <option value="NUEVO IDEAL">Nuevo Ideal</option>
                                  <option value="SAN JUAN DE GUADALUPE">San Juan de Guadalupe</option>
                                  <option value="MEZQUITAL">Mezquital</option>
                                  <option value="SANTIAGO PAPASQUIARO">Santiago Papasquiaro</option>
                                  <option value="TLAHUALILO">Tlahualilo</option>
                                  <option value="PUEBLO NUEVO">Pueblo Nuevo</option>
                                  <option value="TOPIA">Topia</option>
                                  <option value="VICENTE GUERRERO">Vicente Guerrero</option>
                                  <option value="MAPIMI">Mapimí</option>
                                  <option value="SUCHIL">Súchil</option>
                                  <option value="POANAS">Poanas</option>
                                  <option value="GUADALUPE VICTORIA">Guadalupe Victoria</option>
                                  <option value="PEÑON BLANCO">Peñón Blanco</option>
                                  <option value="RODEO">Rodeo</option>
                                  <option value="HIDALGO">Hidalgo</option>
                                  <option value="EL ORO">El Oro</option>
                                  <option value="CONETO DE COMONFORT">Coneto de Comonfort</option>
                                  <option value="NAZAS">Nazas</option>
                                  <option value="SANTIAGO LLANO GRANDE"> Santiago Llano Grande </option>
                                  <option value="OCAMPO">Ocampo</option>
                                  <option value="GUANACEVI">Guanaceví</option>
                                  <option value="SUCHIL">Súchil</option>
                                  <option value="SANTA CLARA">Santa Clara</option>
                                  <option value="SAN BERNARDO">San Bernardo</option>
                                  <option value="SAN DIMAS">San Dimas</option>
                                  <option value="INDE">Indé</option>
                                  <option value="TAMAZULA">Tamazula</option>
                                  <option value="NOMBRE DE DIOS">Nombre de Dios</option>
                                  <option value="SANTIAGO BAYACORA">Santiago Bayacora</option>
                                  <option value="EL SALTITO">El Saltito</option>
                                  <option value="SANTA CATARINA DE TEPEHUANES">Santa Catarina de Tepehuanes</option>
                                  <option value="SANTIAGO PAPASQUIARO">Santiago Papasquiaro</option>
                                  <option value="LA BREÑA">La Breña</option>
                                  <option value="PANUCO DE CORONADO">Pánuco de Coronado</option>
                                  <option value="OTAES">Otáez</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.municipio}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3 mt-3">
                              <p style={{ fontWeight: "bold", textDecoration: "underline", }}>
                                Tipo Declaración
                              </p>
                              <Form.Group as={Col} controlId="validationFormik102" className="position-relative">
                                <Form.Label> Tipo de Declaración</Form.Label>
                                <Form.Select
                                  name="declaracion"
                                  value={formik.values.declaracion}
                                  onChange={formik.handleChange}
                                  isValid={formik.touched.declaracion && !formik.errors.declaracion}
                                  isInvalid={!!formik.errors.declaracion && formik.touched.declaracion} //verificar bajo que condicion se valida
                                >
                                  <option value="">Seleccione...</option>
                                  <option value="1">Declaración Normal</option>
                                  <option value="2">Declaración Complementaria</option>
                                </Form.Select>

                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.declaracion}</Form.Control.Feedback>
                              </Form.Group>
                            </Row>
                            {formik.values.declaracion === "2" &&
                              <>
                                <Row className='mb-3'>
                                  <Form.Group as={Col} className='position-relative'>
                                    <Form.Label>No. de Complementaria</Form.Label>
                                    <Form.Control
                                      type='text'
                                      name='numComplementaria'
                                      maxLength={1}
                                      value={formik.values.numComplementaria}
                                      onChange={handleInputChangeCodigo}
                                      isValid={formik.touched.numComplementaria && !formik.errors.numComplementaria}
                                      isInvalid={!!formik.errors.numComplementaria && formik.touched.numComplementaria}
                                    />
                                    <Form.Control.Feedback type='invalid' tooltip>{formik.errors.numComplementaria}</Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <Form.Group as={Col} className="position-relative">
                                    <Form.Label>
                                      Fecha de declaración anterior
                                    </Form.Label>
                                    <Form.Control
                                      type="date"
                                      name="fechaAnteriorDec"
                                      value={formik.values.fechaAnteriorDec}
                                      onChange={formik.handleChange}
                                      isValid={formik.touched.fechaAnteriorDec && !formik.errors.fechaAnteriorDec}
                                      isInvalid={formik.touched.fechaAnteriorDec && !!formik.errors.fechaAnteriorDec}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.fechaAnteriorDec}</Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                  <Form.Group as={Col} className="position-relative" style={{ display: "flex", justifyContent: "start" }}>
                                    <Form.Check
                                      type='checkbox'
                                      name="correccionFiscal"
                                      label="¿Es Corrección fiscal?"
                                      reverse
                                      value={formik.values.correccionFiscal}
                                      onChange={formik.handleChange}
                                      isValid={formik.touched.correccionFiscal && !formik.errors.correccionFiscal}
                                      isInvalid={!!formik.errors.correccionFiscal && formik.touched.correccionFiscal} //verificar bajo que condicion se valida
                                    />


                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.correccionFiscal}</Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                              </>
                            }
                          </Box>

                        ) : (
                          activeStep === 1 ? (
                            <FormArrendamiento2 handleBack={handleBack} setInfoCal={setInfoCal} infoCal={infoCal} CalcularArrendamiento={CalcularArrendamiento} handleNext={handleNext} info={info} steps={steps} activeStep={activeStep} />
                          ) :
                            <FormPagos
                              metodoPago={metodoPago}
                              setMetodoPago={setMetodoPago}
                           
                              generarPresupuesto={() => {
                                //GenerarLigaPago("presupuesto") 
                                CalcularArrendamiento();
                              }}
                              continuarPago={setContinuarPago}
                            />
                        )



                        }
                      </React.Fragment>
                    )}



                  </Box>




                  {activeStep !== 1 &&

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      {
                        activeStep === steps.length - 1 &&
                        <ButtonB
                          variant='secondary'
                          disabled={activeStep === 0}
                          onClick={handleBack}


                        >
                          Anterior
                        </ButtonB>
                      }
                      <Box sx={{ flex: '1 1 auto' }} />
                      {
                        activeStep === steps.length - 1 &&
                        <ButtonB
                          onClick={() => {
                            /*  setTipoAccion(tipo.PRESUPUESTO) */
                            setLoadingPago(true);
                            GenerarLigaPago("presupuesto")
                          }}
                          variant='secondary' className='mr-3'>
                          Descargar Presupuesto
                        </ButtonB>
                      }
                     <ButtonB  onClick={async () => {

                        if (activeStep === steps.length - 1) {

                          if (metodoPago === "pago1") {
                            /* setTipoAccion(tipo.PAGO) */
                            GenerarLigaPago("pago1")

                            setLoadingPago(true);
                            //generarTransaccion(tipo.PAGO)
                          } else {
                            /* setTipoAccion(tipo.REFERENCIA) */
                            GenerarLigaPago("pago2")

                            setLoadingPago(true);
                            //generarTransaccion(tipo.REFERENCIA)
                          }

                          return;
                        }

                       await formik.handleSubmit()
                      }} variant='secondary'
                        disabled={ (activeStep === steps.length - 1 && continuarPago === false)}
                      >
                        {activeStep === steps.length - 1 ? metodoPago === "pago1" ? 'Continuar al pago' : "Descargar referencia de pago" : calculo?.importe !== 0 ? 'Siguiente' : 'Realizar pago'}
                      </ButtonB>
                    </Box>
                  }

                </Form>
              </View>
        }
      </View>
      <ResumenCard tipo="ISR" calculoImp={handleNext} ligaPagoMit={ligaPagoMit} idForm={idForm}/>
      <ModalActions
        title={"Se ha generado la referencia de pago con éxito"}
        content={""}
        icon={"check"}
        show={pagoExitoModal}
        onHide={() => {
          setPagoExitoModal(false);

        }}
        buttonAction={() => {
          setPagoExitoModal(false);

        }}
      />
      <ModalActions
        title={"Hubo un problema"}
        content={"por favor, vuelve a intentarlo"}
        icon={"error"}
        show={pagoFallidoModal}
        onHide={() => {
          setPagoFallidoModal(false);
        }}
        buttonAction={() => {
          setPagoFallidoModal(false);
        }}
      />
      <ToastContainer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingPago}

      >
        <h1>Esperando Respuesta</h1>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}