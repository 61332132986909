import React, { useState, useEffect } from "react";
import {
    View,
    PhoneNumberField
} from "@aws-amplify/ui-react";
import MaskedInput from 'react-text-mask';
import * as formik from 'formik';
import * as yup from 'yup';
import { Col, Row, Form, Button, Modal } from "react-bootstrap";
import { getCurrentUser, fetchUserAttributes, updateUserAttributes, confirmUserAttribute, sendUserAttributeVerificationCode } from 'aws-amplify/auth';
import { useField } from "formik";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
export const ChangePhone = () => {
    const { Formik } = formik;
    const navigate = useNavigate();
    const dialCodeRef = React.useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [userInfo, setUserInfo] = useState("");
    const [userInfoValidate, setUserInfoValidate]=useState("");
    const [show, setShow] = useState(false);
    const [band, setBand]=useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChangeCode = (event) => {
        let values;
        values = event.target.value.replace(/[^0-9]/g, '');
        setInputValue(values);

    };
    const schema = yup.object().shape({
        telefono: yup.string().required("Este Campo es Obligatorio").matches(/^(\(\d{3}\) \d{3}-\d{4}|\d{10})$/, 'Formato no valido'),

    });
    const handleUpdateProfile = async (userAttributes) => {
        try {
            // Obtener el usuario actual
            const user = await getCurrentUser();

            if (!user) {
                throw new Error('No hay usuario autenticado.');
            }
            console.log(userAttributes)
            console.log(user)
            // Actualizar los atributos del usuario
            const updateResults = await updateUserAttributes({ userAttributes });

            console.log('Resultados de la actualización:', updateResults);
            handleShow()
            setBand(!band)
        } catch (error) {
            console.error('Error al actualizar los datos del usuario:', error.name);
            let errorMessage = "Ocurrió un error al actualizar el número de teléfono.";
            if (error.name === 'AliasExistsException') {
                errorMessage = "El número de teléfono ya está asociado a otra cuenta.";
            } else if (error.name === 'InvalidParameterException') {
                errorMessage = "El número de teléfono proporcionado no es válido.";
            } else if (error.name === 'NotAuthorizedException') {
                errorMessage = "No estás autorizado para realizar esta acción.";
            }
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };
    const sendVerificationCodeToNewEmail = async () => {
        try {
            await sendUserAttributeVerificationCode({ userAttributeKey: 'phone_number' });
           
            setBand(!band)
        } catch (error) {
            console.error('Error al enviar el código de verificación:', error);
        }
    };
    const handleConfirmAttribute = async ({ userAttributeKey, confirmationCode }) => {

        try {
            await confirmUserAttribute({ userAttributeKey, confirmationCode })

            console.log("Resultados de la confirmacion")
            handleClose()
            toast.success("Cambio realizado con éxito", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBand(!band)
            navigate("/Configuracion")
        } catch (error) {
            console.error('Error al actualizar los datos del usuario:', error);
            toast.error('Ocurrió un error al actualizar los datos del perfil.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const CustomPhoneNumberField = ({ name, disabled, touched, errors }) => {
        const [field, meta, helpers] = useField(name);

        const { setValue } = helpers;

        const Validar = (e) => {
            setValue(e.target.value)
        }
        return (
            <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="(999) 999-9999"
                value={field.value}
                onChange={(e) => Validar(e)}

                render={(inputRef, props) => (
                    <PhoneNumberField
                        {...props}
                        dialCodeRef={dialCodeRef}
                        labelHidden
                        isDisabled={disabled}
                        defaultDialCode="+52"
                        ref={inputRef} // <-- Aquí cambiamos inputRef por ref
                        hasError={meta.touched && !!meta.error}
                        className={` ${touched.telefono && errors.telefono ? 'is-invalid' : touched.telefono ? 'is-valid' : ''}`}

                    />
                )}
            />
        );
    };
    useEffect(() => {
        const obtenerInfo = async () => {
            const user = await fetchUserAttributes();
            setUserInfo(user)
            setUserInfoValidate(user)
            console.log(user)
        }
        obtenerInfo()
    }, [band])


    return (
        <>
            <div className="FormTramite">
                <View className="FormTitle">Configuración de Cuenta</View>
                <View className='FormDescripcion'>Actualiza tu información</View>
                <Row className='mb-0'>
                    <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                        <button type="button" onClick={() => navigate("/Configuracion")}
                            className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                            <FaArrowLeft /> Volver al Inicio
                        </button>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                    </Form.Group>
                    <Form.Group className='d-grid gap-2' as={Col} md="3">
                    </Form.Group>
                </Row>
                <Formik
                    validationSchema={schema}
                    initialValues={{

                        telefono: "",
                    }
                    }
                    onSubmit={async (valores, { resetForm }) => {
                        console.log("Valores", valores);
                        const userAu = await fetchUserAttributes();
                        console.log(userAu);
                        const cleanedPhoneNumber = valores.telefono.replace(/[^\d]/g, '');
                        const Telefono = `${dialCodeRef.current.value}${cleanedPhoneNumber}`
                        handleUpdateProfile({
                            phone_number: Telefono,
                        });

                    }
                    }
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => (

                        <Form onSubmit={handleSubmit} noValidate className='ViewActa mt-3'>

                            <Row className="mb-3">
                                <Form.Label>Número de Teléfono Actual</Form.Label>
                                <Form.Control
                                    defaultValue={userInfo.phone_number}
                                    readOnly
                                />
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} className="position-relative">
                                    <Form.Label>Ingrese el Número de Teléfono</Form.Label>
                                    <CustomPhoneNumberField name="telefono" touched={touched} errors={errors} />
                                {userInfoValidate.phone_number_verified === "false" &&
                                    <Button variant="link" onClick={handleShow}>Verificar Número de Teléfono</Button>

                                    }
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Button type="submit" style={{ width: "50%", margin: "auto" }} variant="secondary">Guardar cambios</Button>
                            </Row>
                        </Form>

                    )}


                </Formik>
            </div>
            <ToastContainer />
            <Modal show={show} >

                <Modal.Body>
                    <Row className="mb-3" style={{ display: "flex", margin: "auto", textAlign: "center" }}>
                        <Form.Label>Hemos enviado un código de verificación a tu número de teléfono por favor ingresalo en el siguiente campo.</Form.Label>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group className="position-relative" as={Col}>
                            <Form.Label>Ingrese el Código de Verificación</Form.Label>

                            <Form.Control
                                name="code"
                                value={inputValue}
                                onChange={(e) => handleChangeCode(e)}
                            />

                           {/*  <Button onClick={() => sendVerificationCodeToNewEmail()} variant="link">Reenviar Código</Button> */}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Button variant="secondary" onClick={() => handleConfirmAttribute({
                            userAttributeKey: "phone_number",
                            confirmationCode: inputValue
                        })}>CONFIRMAR</Button>
                        <Button variant="link" onClick={handleClose}>CANCELAR</Button>
                    </Row>
                </Modal.Body>

            </Modal>
        </>
    )
}
